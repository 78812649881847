import { useBuildIDStore } from "./store"
import { AttributesViewEnum } from "./constant"
import { ROLE_ID } from "@/types/config"

export const quickFiltersFormatter = (filters: any[]) => {
  return filters
    .filter(item => {
      if (Array.isArray(item.value)) return item.value.length > 0
      return Boolean(item.value)
    })
    .map(item => ({
      field: item.id,
      value: Array.isArray(item.value) ? item.value.map((v: any) => v.id) : item.value.id,
    }))
}

export const getQuickFilters = (filters: any[]) => {
  const normalFilters: any[] = []
  const attributeFilterObj: any = {}
  filters.forEach((item: any) => {
    if (item.id === "build_id_attribute" && item.value?.id) {
      attributeFilterObj.field = item.value.id
    } else if (item.id === "attribute_value" && item.value?.id) {
      attributeFilterObj.value = item.value.id
    } else {
      normalFilters.push(item)
    }
  })

  const quickFilters: any[] = quickFiltersFormatter(normalFilters)
  if (attributeFilterObj.field && attributeFilterObj.value) {
    quickFilters.push(attributeFilterObj)
  }
  return quickFilters
}

export const generateChangesToOptions = (build_id_map: any[], component_map: any[]) => {
  const changesToOptions: any[] = []
  build_id_map.forEach(([name, id]: [string, string]) => {
    changesToOptions.push({ id, name, group: "Build ID" })
  })
  component_map.forEach(([name, id]: [string, string]) => {
    changesToOptions.push({ id, name, group: "Component" })
  })
  return changesToOptions
}

/**
 * Find option from vvt and replace the code with name
 * @param obj
 * @param group
 * @param attr
 */
const replaceCodeWithName = (obj: any, group: string, attr: string) => {
  const vvtOptions: any[] = useBuildIDStore.getState().filtersOptions.vvt_options
  obj[attr] = vvtOptions.find(op => op.group === group && op.id == obj[attr])?.name ?? obj[attr]
}

export const codes = [
  ["Paper", "print_paper"],
  ["Paper", "inventory_paper_instructions"],
  ["Ink", "print_ink"],
  ["FinishedSize", "paper_size_id"],
  ["FinishedSize", "fulfill_finished_size"],
  ["FoldInstructions", "fulfill_fold_instruction"],
  ["InsertType", "fulfill_insert_type"],
  ["ShippingMethod", "ship_method_id"],
  ["FinishingType", "finishing_type_id"],
  ["LanguageCode", "language_code"],
  ["MatchType", "match_type_bindery_id"],
  ["MatchType", "match_type_inner_id"],
  ["MatchType", "match_type_main_id"],
  ["ComponentTypeCode", "component_type_code_id"],
  ["CoverTextMatch", "cover_match"],
  ["ShippingClass", "ship_shipping_class"],
  ["ShippingTypeID", "ship_shipping_type"],
  ["ShippingMethod", "ship_pickup_shipping_method"],
  ["EnclosureType", "type_fd"],
  ["HouseHolding", "house_holding_count"],
  ["LaminateGauge", "fulfill_laminate_gauge"],
  ["LaminateImposition", "fulfill_laminate_imposition"],
  ["PostalToggle", "postal_toggle_id"],
  ["InkJetInstructions", "fulfill_ink_jet_instruction"],
  ["InkJetline", "fulfill_ink_jet_line"],
  ["PrintOrder", "print_order"],
  ["PostalSort", "postal_sort"],
  ["Tabbing_Glueing", "fulfill_tabbing_glueing"],
]
const generateEnclosureTypeCodes = () => {
  const arr = []
  for (let i = 1; i < 15; i++) {
    if (i < 10) arr.push(["EnclosureType", `type_enc0${i}`])
    else arr.push(["EnclosureType", `type_enc${i}`])
  }
  return arr
}
export const enclosureTypeCodes = generateEnclosureTypeCodes()

/**
 * Handle the list of attrs to be mapped from code to name
 * @param obj
 */
export const handleBuildIdAttrsMap = (obj: Record<string, any>) => {
  const allCodes = [...codes, ...enclosureTypeCodes]
  allCodes.forEach((item: any) => replaceCodeWithName(obj, item[0], item[1]))
  return obj
}

export const getViewAttrsDiff = (
  item: any,
  mapData: any[],
  attributesView: AttributesViewEnum | undefined,
  invalidEnclosures: any,
) => {
  return mapData.filter(([, field]) => {
    const isModified = item.diffKeys.includes(field)
    if (
      attributesView === AttributesViewEnum.UPDATED &&
      !isModified &&
      !Object.keys(invalidEnclosures).includes(field)
    )
      return false

    item.before = handleBuildIdAttrsMap(item.before)
    item.after = handleBuildIdAttrsMap(item.after)
    if (typeof item.before[field] === "boolean") {
      item.before[field] = item.before[field].toString()
    }
    if (typeof item.after[field] === "boolean") {
      item.after[field] = item.after[field].toString()
    }
    if (
      [null, undefined, ""].includes(item.before[field]) &&
      [null, undefined, ""].includes(item.after[field])
    )
      return false
    return true
  })
}

export const getKPIReportPermission = (session: any) => {
  const teams = session?.teams || []
  const isTeamManager = teams.some(
    (team: any) =>
      (team.client_id && team.is_manager) || team.team_name === "Plant BuildID Approvers",
  )
  const roles = session?.roles || []
  const isAdmin = roles.some((role: any) => role.role_id === ROLE_ID.ADMIN)

  return isAdmin || isTeamManager
}
