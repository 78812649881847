export enum ThemeModePreference {
  PAGE = "main",
  FIELD = "themeMode",
}
export type PreferenceChoiceTheme = "light" | "dark" | "system"

export enum TimezonePreference {
  PAGE = "main",
  FIELD = "timezone",
}

// Pacific Time -> America/Los_Angeles
// Mountain Time -> America/Denver
// Central Time -> America/Chicago
// Eastern Time -> America/New_York
export type PreferenceChoiceTimezone =
  | "America/Los_Angeles"
  | "America/Denver"
  | "America/Chicago"
  | "America/New_York"
  | "System"

export enum NotificationPreference {
  PAGE = "main",
  FIELD = "notification",
}

export enum RecordRoutePreference {
  PAGE = "App",
  FIELD = "record_route",
}

export enum SideBarCollapsedPreference {
  PAGE = "App",
  FIELD = "sideBarCollapsed",
}

export enum ReleaseVersionPreference {
  PAGE = "App",
  FIELD = "release_version",
}

export enum ShowClientLogoPreference {
  PAGE = "Dashboard_UI",
  FIELD = "showClientLogo",
}

export enum HighContrastPreference {
  PAGE = "Dashboard_UI",
  FIELD = "highContrast",
}

export enum SelectedFilterPreference {
  PAGE = "Dashboard_UI",
  FIELD = "selected_filter",
}

export enum SelectedSortByPreference {
  PAGE = "Dashboard_UI",
  FIELD = "selected_sort_by",
}

export enum AutoRefreshPreference {
  PAGE = "Dashboard_UI",
  FIELD = "auto_refresh",
}

export enum CollapsedFilterFolderPreference {
  PAGE = "Dashboard_UI",
  FIELD = "collapsed_filter_folder",
}

export enum AccountFiltersPreference {
  PAGE = "accountPage",
  FIELD = "filters",
}

export enum ContactFiltersPreference {
  PAGE = "contactPage",
  FIELD = "filters",
}

export enum OpportunityFiltersPreference {
  PAGE = "opportunityPage",
  FIELD = "filters",
}

export enum ProposalFiltersPreference {
  PAGE = "proposalPage",
  FIELD = "filters",
}

export enum SLAReportDetailsFiltersPreference {
  PAGE = "SLAReportDetailsPage",
  FIELD = "filters",
}

export enum TeamPageFiltersPreference {
  PAGE = "teamPage",
  FIELD = "filters",
}

export enum WorklogProjectPagePreference {
  PAGE = "worklogProjectPage",
  FIELD = "filters",
}

export enum WorklogProjectPageDateFilterPreference {
  PAGE = "worklogProjectPage",
  FIELD = "dateFilter",
}

export enum WorklogProjectSummaryPagePreference {
  PAGE = "worklogProjectSummaryPage",
  FIELD = "filter",
}

export enum WorklogResourcePagePreference {
  PAGE = "worklogResourcePage",
  FIELD = "filters",
}

export enum WorklogResourcePageDateFilterPreference {
  PAGE = "worklogResourcePage",
  FIELD = "dateFilter",
}

export enum MessageCenterFiltersPreference {
  PAGE = "MessageCenterPage",
  FIELD = "filters",
}

export enum ShowExpandStepPreference {
  PAGE = "Dashboard_UI",
  FIELD = "expandStep",
}

export enum DateOrderPreference {
  PAGE = "Dashboard_UI",
  FIELD = "dateSort",
}

export enum ClientProfilePreference {
  PAGE = "AllClientsPage",
  FIELD = "sorts",
}

export enum CPATicketsDateFiltersPagePre {
  PAGE = "CPATicketsPage",
  FIELD = "dateFilter",
}

export enum CPATicketsFiltersPagePre {
  PAGE = "CPATicketsPage",
  FIELD = "filter",
}

export enum ClientDataFileFiltersPagePre {
  PAGE = "ClientDataFilePage",
  FIELD = "filter",
}

export enum ClientDataFileDateFiltersPagePre {
  PAGE = "ClientDataFilePage",
  FIELD = "dateFilter",
}

export enum CRMProposalFiltersPreference {
  PAGE = "CRMProposalPage",
  FIELD = "filters",
}

export enum BuildIDUpdatesPagePrefer {
  PAGE = "BuildIDUpdatesPage",
  FIELD = "filter",
}

export enum BuildIDUpdatesPagePreferQuickFilter {
  PAGE = "BuildIDUpdatesPage",
  FIELD = "quickFilter",
}

export enum AllBuildIDsPagePrefer {
  PAGE = "AllBuildIDsPage",
  FIELD = "filter",
}

export enum InventoryForecastPagePrefer {
  PAGE = "InventoryForecastPage",
  FIELD = "filters",
}

export enum ForecastClientPagePrefer {
  PAGE = "ForecastClientPage",
  FIELD = "filters",
}

export enum ForecastPageClientTablePrefer {
  PAGE = "ForecastPageClientTable",
  FIELD = "filters",
}

export enum ForecastPageInventoryTablePrefer {
  PAGE = "ForecastPageInventoryTable",
  FIELD = "filters",
}

export enum ForcastPageMachineTablePrefer {
  PAGE = "ForecastPageMachineTable",
  FIELD = "filters",
}

export enum ForecastClientTableSortPrefer {
  PAGE = "ForecastPageClientTableSort",
  FIELD = "sort",
}

export enum ForecastInventoryTableSortPrefer {
  PAGE = "ForecastPageInventoryTableSort",
  FIELD = "sort",
}

export enum ForecasteMachineTableSortPrefer {
  PAGE = "ForecastPageMachineTableSort",
  FIELD = "sort",
}

// build id kpi report tab page
export enum BuildIdKPIReportPageDateFilterPrefer {
  PAGE = "BuildIdKPIReportPage",
  FIELD = "dateFilter",
}

export enum BuildIdKPIReportPageResourceFilterPrefer {
  PAGE = "BuildIdKPIReportPage",
  FIELD = "resourceFilter",
}

export enum SLAAdherenceDateFilterPrefer {
  PAGE = "SLAAdherencePage",
  FIELD = "dateFilter",
}

export enum SLAAdherenceResourceFilterPrefer {
  PAGE = "SLAAdherencePage",
  FIELD = "resourceFilter",
}

export enum SurveyJobsPageDateFilterPrefer {
  PAGE = "SurveyJobsPage",
  FIELD = "dateFilter",
}

export enum SurveyJobsPageResourceFilterPrefer {
  PAGE = "SurveyJobsPage",
  FIELD = "resourceFilter",
}

// digital survey page
export enum DSDateFilterPrefer {
  PAGE = "DigitalSurveysPage",
  FIELD = "dateFilter",
}
export enum DSResourceFilterPrefer {
  PAGE = "DigitalSurveysPage",
  FIELD = "resourceFilter",
}

// survey scans data-graph page
export enum SSDGDateFilterPrefer {
  PAGE = "SurveyScansDataGraphPage",
  FIELD = "dateFilter",
}
export enum SSDGResourceFilterPrefer {
  PAGE = "SurveyScansDataGraphPage",
  FIELD = "resourceFilter",
}

// Billing Report Page
export enum BRDateFilterPrefer {
  PAGE = "BillingReportPage",
  FIELD = "dateFilter",
}
export enum BRResourceFilterPrefer {
  PAGE = "BillingReportPage",
  FIELD = "resourceFilter",
}

// surveys template page
export enum STPageResourceFilterPrefer {
  PAGE = "SurveyTemplatePage",
  FIELD = "resourceFilter",
}

// alert setting page
export enum AlertSettingResourceFilterPrefer {
  PAGE = "AlertSettingPage",
  FIELD = "resourceFilter",
}
