import { useGlobalStore } from "@/stores/global"
import { useSessionStore } from "@/stores/session"
import { ThemeMode } from "@/types/config"
import { Theme } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid"

/**
 * Convert a number to locale string, e.g, 3000 to 3,000
 */
export const numToLocalString = (value: number) => value.toLocaleString()

/**
 * Truncate value to be less than given length
 * @param value the string value to be truncated
 * @param length the length that the value need to be truncated
 */
export const truncateLongString = (value: string, length = 30) => {
  return value.length > length ? `${value.substring(0, length)}...` : value
}

/**
 * Change the first letter of the string to uppercase
 * @param value the string value to be capitalized
 */
export function capitalizeFirstLetter(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

/**
 * The recursive deep clone method
 */
export function deepClone<T>(obj: T): T {
  if (typeof obj !== "object" || obj === null) {
    return obj
  }

  if (obj instanceof Map) {
    const clone = new Map()
    obj.forEach((value, key) => {
      clone.set(key, deepClone(value))
    })
    return clone as T
  }

  const clone: any = Array.isArray(obj) ? [] : {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clone[key] = deepClone(obj[key])
    }
  }

  return clone as T
}

/**
 * Note that this is not a strict equality comparison!!!
 * In the UI is the same as the null is considered to be equal,
 * such as the null character and null and undefined,
 * numerical types and string types of values are also equal.
 */
export const isEqual = (value1: any, value2: any): boolean => {
  if (typeof value1 === "number") value1 = String(value1)
  if (typeof value2 === "number") value2 = String(value2)

  if (value1 instanceof Map && value2 instanceof Map) {
    const array1 = Array.from(value1)
    const array2 = Array.from(value2)

    return JSON.stringify(array1) === JSON.stringify(array2)
  }

  if (
    (value1 === "" && (value2 === null || value2 === undefined)) ||
    (value2 === "" && (value1 === null || value1 === undefined))
  ) {
    return true
  }

  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) {
      return false
    } else {
      for (let i = 0; i < value1.length; i++) {
        if (!isEqual(value1[i], value2[i])) return false
      }
    }
    return true
  }

  if (typeof value1 !== typeof value2) {
    return false
  }

  if (typeof value1 === "object" && value1 !== null && value2 !== null) {
    const keys1 = Object.keys(value1).filter(key => key !== "id")
    const keys2 = Object.keys(value2).filter(key => key !== "id")

    if (keys1.length !== keys2.length) {
      return false
    }

    for (const key of keys1) {
      if (!isEqual(value1[key], value2[key])) {
        return false
      }
    }

    return true
  }

  return value1 === value2
}

/**
 * Compare numbers
 * @param a
 * @param b
 */
export const compareNumbers = (a: any, b: any) => {
  if (a === SORT_MIN_NUMBER || b === SORT_MIN_NUMBER) return 0
  return Number(a.toString().replace(",", "")) - Number(b.toString().replace(",", ""))
}

/**
 * Compare string with specific value not to be sorted
 * @param a
 * @param b
 */
export const compareString = (a: any, b: any) => {
  if (a === SORT_MIN_NUMBER || b === SORT_MIN_NUMBER) return 0
  return a.localeCompare(b)
}

export const SORT_MIN_NUMBER = -999999999

/**
 * Custom sort by letters first, then by numbers
 * @param a
 * @param b
 */
export const customStrWithNumSort = (a: string, b: string) => {
  const matchA = a.match(/(\D+)(\d*)/)
  const matchB = b.match(/(\D+)(\d*)/)

  if (!matchA || !matchB) {
    throw new Error("Invalid input: strings should contain letters and/or numbers")
  }

  const letterA = matchA[1]
  const numberA = matchA[2] || String(Number.MIN_SAFE_INTEGER)
  const letterB = matchB[1]
  const numberB = matchB[2] || String(Number.MIN_SAFE_INTEGER)

  // Compare letters
  const letterComparison = letterA.localeCompare(letterB)

  // If letters are the same, compare numbers
  if (letterComparison === 0) {
    return parseInt(numberA, 10) - parseInt(numberB, 10)
  }

  return letterComparison
}

/**
 * Calculates the width of the string at the specified size.
 * @param character
 * @param fontSize
 */
export const getCharacterWidth = (character: string, fontSize: string): number => {
  const tempElement = document.createElement("span")
  tempElement.style.fontSize = fontSize
  tempElement.innerText = character
  document.body.appendChild(tempElement)
  const width = tempElement.getBoundingClientRect().width
  document.body.removeChild(tempElement)
  return width
}

/**
 * Get the style based on the MUI theme mode
 * @param theme the theme
 * @param darkStyle the style when the theme mode is dark
 * @param lightStyle the style when the theme mode is light
 */
export const getThemeStyleByMui = (theme: Theme, darkStyle?: any, lightStyle?: any) =>
  theme.palette.mode === ThemeMode.Dark ? darkStyle : lightStyle

/**
 * Get the style based on the store
 * @param darkStyle the style when the theme mode is dark
 * @param lightStyle the style when the theme mode is light
 */
export const getThemeStyleByStore = (darkStyle?: any, lightStyle?: any) =>
  useGlobalStore.getState().themeMode === ThemeMode.Dark ? darkStyle : lightStyle

/**
 * Debounce function
 * @param callback the function to be debounced
 * @param delay the delay time
 */
export const debounce = <T extends (...args: any[]) => any>(callback: T, delay: number) => {
  let timer: ReturnType<typeof setTimeout> | null = null

  return (...args: Parameters<T>): void => {
    if (timer) clearTimeout(timer)

    timer = setTimeout(() => {
      callback(...args)
      timer = null
    }, delay)
  }
}

export const isValidTimezoneValue = (value: string) => {
  return [
    "America/Los_Angeles",
    "America/Denver",
    "America/Chicago",
    "America/New_York",
    "System",
  ].includes(value)
}

/**
 * Iterate over an object
 * @param obj the object to be iterated
 * @param callback the callback function to be called for each element
 */
export const each = (obj: any, callback: (value: any, key: any, index?: number) => void) => {
  if (obj === null || typeof obj !== "object") return
  Reflect.ownKeys(obj).forEach((key, index) => {
    callback(obj[key], key, index)
  })
}

/**
 * Extracts and sorts client group options from product options in GlobalStore.
 * @param productOptions Array of products with group information.
 * @returns Sorted array of client group options.
 */
export const getClientGroupOptions = (productOptions: any[]): any[] => {
  const clientGroupMap = new Map<number, any>()
  productOptions?.forEach((item: any) => {
    if (item.group_id && !clientGroupMap.has(item.group_id)) {
      clientGroupMap.set(item.group_id, { name: item.group, id: item.group_id })
    }
  })
  return Array.from(clientGroupMap.values()).sort((a, b) => a.name.localeCompare(b.name))
}

export const getLocalStorageItem = (key: string) => localStorage.getItem(key)

export const getLocalStorageToken = () => {
  const insightStorage = getLocalStorageItem("InSight")
  if (insightStorage) {
    const obj = JSON.parse(insightStorage)
    return obj.state?.session?.accessToken ?? null
  }
  return null
}

/**
 * get the URL search parameters
 * @returns the URL search parameters
 */
export const getUrlSearchParams = () => {
  const params = new URLSearchParams(window.location.search)
  return Object.fromEntries(params.entries())
}

export const stringArrToObjArr = (arr: string[]): any[] => {
  return arr.map(item => ({ name: item, id: item }))
}

export const resourceFiltersFormatter = (filters: any[]) => {
  return filters
    .filter(item => {
      if (Array.isArray(item.value)) return item.value.length > 0
      return Boolean(item.value)
    })
    .map(item => ({
      field: item.id,
      value: Array.isArray(item.value)
        ? item.id === "sla_missed_cause"
          ? item.value.map((v: any) => v.id).join(",")
          : item.value.map((v: any) => v.id)
        : item.value.id,
    }))
}

export const dataGridDefaultRenderCell = (params: GridRenderCellParams) => params?.value ?? "-"

export const isTeamMember = (team: string | number) =>
  // check if user is in the team, string if team name, number if client id
  useSessionStore
    .getState()
    .session?.teams?.some?.((item: any) => item?.team_name === team || item?.client_id === team)
