import http from "@/utils/http"
import { ClientItem } from "@/api/dashboard/types";

export const userApi = {
  /**
   * Get user list
   */
  getUsers(params: {
    search_text?: string
    pagination?: boolean
    page?: number
    perPage?: number
    sortColumn?: string
    reversedOrder?: boolean
    headerFilters?: string
    searchText?: string
  }, showSpinner = true) {
    return http.get("/v1/admin/users", { params, showSpinner })
  },
  /**
   * Update user
   */
  updateUser(userId: number, data: any) {
    return http.patch(`/v1/admin/users/${userId}`, data)
  },
  /**
   * Get role list
   */
  getRoles() {
    return http.get("/v1/admin/roles")
  },
  /**
   * Get client options to be used in client advocate management
   */
  getClientOptions(owner = false) {
    return http.get<ClientItem[]>(`/v1/admin/users/client_options?owner=${owner}`)
  },
  /**
   * Get client and product options to be used
   */
  getClientProductOptions() {
    return http.get(`/v1/admin/users/client_products`)
  },
  /**
   * Get all usernames
   */
  getUserOptions() {
    return http.get(`/v1/admin/users/user_options`)
  },
  /**
   * Get all usernames
   */
  getEmployeeOptions() {
    return http.get(`/v1/admin/users/employee_options`)
  },
  /**
   * Get all usernames
   */
  getHaveClientEmployeeOptions() {
    return http.get(`/v1/admin/users/client/employee_options`)
  },
  /**
   * Get user info
   */
  getUserInfo(userId: number) {
    return http.get(`/v1/admin/users/${userId}`)
  },
  /**
   * Get user profile
   */
  async getUserProfile() {
    return (
      await http.get<{
        username: string;
        ad_account: string;
        company: string;
        department: string;
        display_name: string;
        email: string;
        job_title: string;
        location: string;
        manager: string;
        roles: {
          id: number;
          name: string;
          description: string;
          role_id: number;
          is_enabled: boolean;
        }[]
      }>('/v1/admin/users/profile')).data
  },
  /**
   * Add user statistic data
   * @param data
   */
  addUserStatistic(data: { audit_type: string, activity: string, description: string }) {
    return http.post(`/v1/admin/users/statistic`, data, { showSpinner: false })
  },
  /**
   * Fetch userprofile for given user
   * @param username
   */
  fetchUserProfile(username: string) {
    return http.get<{
      job_title: string,
      location: string,
      company: string,
      department: string,
      manager: string
    }>(`/v1/admin/users/${username}/profile`)
  },
  /**
   * Get user report data
   */
  getUsersActivities(params: any) {
    return http.get('/v1/admin/users/activities', { params })
  },
}
