import { create } from "zustand"
import { fetchFilterOptions } from "./BuildIDApproval/api"
import { userApi } from "@/api/user"

const initialState = {
  clientList: [] as any[],
  filtersOptions: {} as any,
}

export const useBuildIDStore = create<typeof initialState>()(() => initialState)

export const getFiltersOptions = () => {
  fetchFilterOptions().then(({ data }) => {
    useBuildIDStore.setState({ filtersOptions: data })
  })
}

export const getClientList = () => {
  userApi.getClientProductOptions().then(res => {
    const _data = ((res.data || []) as any[])
      .map(item => ({ id: String(item.project_id), ...item }))
      .sort((a, b) => a.name.localeCompare(b.name))
    useBuildIDStore.setState({ clientList: [..._data] })
  })
}
