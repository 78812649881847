import http from "@/utils/http"
import {
  ContactDetail,
  FetchParams,
  FetchContactsResp,
  OpportunityDetail,
  AccountDetail,
  FetchAccountResp,
  FetchOpportunityResp,
  FetchProposalResp,
  FileItem,
  ClientOption,
} from "./types"
import {
  setCacheUpdatedAt as setCacheUpdatedAtRevenue,
  setLoadingStatus as setLoadingStatusRevenue,
} from "@/pages/ProposalSetup/Report/PrintRevenue/store"
import {
  setCacheUpdatedAt as setCacheUpdatedAtSummary,
  setLoadingStatus as setLoadingStatusSummary,
} from "@/pages/ProposalSetup/Report/PrintSummaryReport/store"
import { useGlobalStore } from "@/stores/global"

export const proposalSetupApis = {
  /**
   * Request contact data
   */
  fetchProposals: (params: FetchParams) => {
    return http.get<FetchProposalResp>("/v1/sales/proposals", { params })
  },
  /**
   * Request contact detail data
   * @param id contact id
   * @param optionalArgs optional arguments
   */
  fetchProposal: (id: string, optionalArgs = "") => {
    return http.get(`/v1/sales/proposals/${id}?${optionalArgs}`)
  },
  /**
   * Create or modify a proposal
   * @param data proposal object
   */
  createOrUpdateProposal: (data: any) => {
    return http.post("/v1/sales/proposals", data)
  },
  /**
   * Update proposal status
   */
  updateStatus: (id: string, status: string) => {
    return http.patch(`/v1/sales/proposals/${id}/status`, { status: status })
  },
  /**
   * Request contact data
   */
  fetchContacts: (params: FetchParams) => {
    return http.get<FetchContactsResp>("/v1/sales/contacts", { params })
  },
  /**
   * Request contact detail data
   * @param id contact id
   */
  fetchContactDetail: (id: string) => {
    return http.get<ContactDetail>(`/v1/sales/contacts/${id}`)
  },
  /**
   * Fetch opportunities from web service
   */
  fetchOpportunities: (params: FetchParams) => {
    return http.get<FetchOpportunityResp>("/v1/sales/opportunities", { params })
  },
  /**
   * Fetch Opportunities detail information
   * @param id opportunity id
   */
  fetchOpportunity: (id: string) => {
    return http.get<OpportunityDetail>(`/v1/sales/opportunities/${id}`)
  },
  /**
   * Fetch Accounts from web service
   */
  fetchAccounts: (params: FetchParams) => {
    return http.get<FetchAccountResp>("/v1/sales/accounts", { params })
  },
  /**
   * Fetch Account detail information
   * @param id opportunity id
   */
  fetchAccount: (id: string) => {
    return http.get<AccountDetail>(`/v1/sales/accounts/${id}`)
  },
  /**
   * Fetch CRMProposals from web service
   */
  fetchCRMProposals: (params: FetchParams) => {
    return http.get<any>("/v1/sales/crm-proposals", { params })
  },
  /**
   * Fetch CRMProposal detail information
   * @param id opportunity id
   */
  fetchCRMProposal: (id: string) => {
    return http.get<any>(`/v1/sales/crm-proposals/${id}`)
  },
  /**
   * Download CRM attachment from web service
   * @param s3FilePath
   */
  downloadCRMAttachment(s3FilePath: string) {
    return http.get(`/v1/sales/crm-proposals/attachment/${s3FilePath}`, { responseType: "blob" })
  },
  /**
   * Fetch options to be used by crm proposal page from web service
   */
  fetchCRMProposalOptions: () => {
    return http.get<any>("v1/sales/crm-proposals/options")
  },
  /**
   * Fetch all required options to be used by proposal setup, includes users, accounts, and opportunities
   */
  fetchOptions: () => {
    return http.get<any>("v1/sales/crm/options")
  },
  /**
   * Upload files to proposal
   * @param id proposal primary key
   * @param data FormData
   */
  uploadFile: (id: string, data: FormData) => {
    return http.post<any>(`v1/sales/proposals/${id}/files`, data)
  },
  /**
   * Fetch all files associated with proposal from web service
   * @param id proposal primary key
   */
  fetchProposalFiles(id: string) {
    return http.get<FileItem[]>(`v1/sales/proposals/${id}/files`)
  },
  /**
   * Download file from web service
   * @param proposalId
   * @param fileId
   */
  downloadFile(proposalId: string, fileId: number) {
    return http.get(`v1/sales/proposals/${proposalId}/files/${fileId}`, { responseType: "blob" })
  },
  /**
   * Delete file from proposal
   * @param proposalId
   * @param fileId
   */
  deleteProposalFile(proposalId: string, fileId: number) {
    return http.delete(`v1/sales/proposals/${proposalId}/files/${fileId}`)
  },
  /**
   * Fetch proposal assets from web service based on given proposal ID
   * @param proposalId primary key for Proposal object
   */
  fetchProposalAssets(proposalId: string) {
    return http.get<any>(`v1/sales/proposals/${proposalId}/assets`)
  },
  /**
   * Create proposal asset
   * @param proposalId primary key for Proposal object
   * @param data proposal asset data
   */
  createProposalAsset(proposalId: string, data: any) {
    return http.post<any>(`v1/sales/proposals/${proposalId}/assets`, data)
  },
  /**
   * Create proposal asset
   * @param proposalId primary key for Proposal object
   * @param assetId Proposal asset primary key
   * @param data proposal asset data
   */
  updateProposalAsset(proposalId: string, assetId: number, data: any) {
    return http.put<any>(`v1/sales/proposals/${proposalId}/assets/${assetId}`, data)
  },
  /**
   * Update price type
   * @param proposalId primary key for Proposal object
   * @param assetId Proposal asset primary key
   * @param price_type price type tier or non_tier
   */
  updateProposalAssetPriceType(proposalId: string, assetId: number, price_type: string) {
    return http.patch<any>(`v1/sales/proposals/${proposalId}/assets/${assetId}`, {
      price_type: price_type,
    })
  },
  /**
   * Update additional price page size
   * @param proposalId primary key for Proposal object
   * @param assetId Proposal asset primary key
   * @param updateObj Patch update given column
   */
  updateProposalAssetColumnValue(proposalId: string, assetId: number, updateObj: any) {
    return http.patch<any>(
      `v1/sales/proposals/${proposalId}/assets/${assetId}/${Object.keys(updateObj)[0]}`,
      updateObj,
    )
  },
  /**
   * Delete proposal asset
   * @param proposalId primary key for Proposal object
   * @param assetId asset id
   */
  deleteProposalAsset(proposalId: string, assetId: number) {
    return http.delete<any>(`v1/sales/proposals/${proposalId}/assets/${assetId}`)
  },
  /**
   * Create proposal asset price point
   * @param proposalId primary key for Proposal object
   * @param assetId asset id
   * @param data Proposal asset price point data
   * @param optionalArgs
   */
  createProposalAssetPricePoint(proposalId: string, assetId: number, data: any, optionalArgs = "") {
    return http.post<any>(
      `v1/sales/proposals/${proposalId}/assets/${assetId}/prices?${optionalArgs}`,
      data,
    )
  },
  /**
   * Delete proposal asset price point from web service
   * @param proposalId Proposal primary key
   * @param assetId Proposal asset primary key
   * @param priceId Proposal asset price point primary key
   */
  deleteProposalAssetPricePoint(proposalId: string, assetId: number, priceId: number | string) {
    return http.delete<any>(`v1/sales/proposals/${proposalId}/assets/${assetId}/prices/${priceId}`)
  },
  /**
   * Delete proposal asset price point from web service
   * @param proposalId Proposal primary key
   * @param assetId Proposal asset primary key
   */
  deleteProposalAssetPricePoints(proposalId: string, assetId: number) {
    return http.delete<any>(`v1/sales/proposals/${proposalId}/assets/${assetId}/prices`)
  },
  /**
   * Create proposal products from web service
   * @param proposalId Proposal primary key
   * @param data new product data
   */
  createProducts(proposalId: string, data: any) {
    return http.post<any>(`v1/sales/proposals/${proposalId}/products`, data)
  },
  /**
   * Create proposal products from web service
   * @param proposalId Proposal primary key
   * @param proposalProductId Proposal product primary key
   * @param data project and product information
   */
  syncProduct(proposalId: string, proposalProductId: any, data: any) {
    return http.patch<any>(
      `v1/sales/proposals/${proposalId}/products/${proposalProductId}/build-ids`,
      data,
    )
  },
  /**
   * Fetch all product and supplemental records associated with given proposal
   * @param proposalId Proposal primary key
   */
  fetchSupplementalProducts(proposalId: string, optionalArgs = "") {
    return http.get<any>(
      `v1/sales/proposals/supplemental-metadata-product-stats/${proposalId}?${optionalArgs}`,
    )
  },
  /**
   * Fetch proposal relationship from web service
   * @param params
   * @returns
   */
  fetchProposalRelationship(params: any) {
    return http.get(`v1/proposal_reports/proposal_relationship_report`, { params })
  },
  /**
   * Fetch all supplemental fields associated with given product
   * @param proposalProductId Proposal product primary key
   */
  fetchSupplementalFields(proposalProductId: string) {
    return http.get<any>(`v1/sales/proposals/supplemental-metadata/product/${proposalProductId}`)
  },
  /**
   * Fetch all product associated with given proposal
   * @param proposalId
   */
  fetchProposalProducts(proposalId: string) {
    return http.get<any[]>(`v1/sales/proposals/${proposalId}/products`)
  },
  /**
   * Delete proposal product from web service
   * @param proposalId
   * @param proposal_product_id
   */
  deleteProposalProduct(proposalId: string, proposal_product_id: string) {
    return http.delete(`v1/sales/proposals/${proposalId}/products/${proposal_product_id}`)
  },
  /**
   *
   * @param proposalId
   * @param proposalProductId
   * @param data
   * @param optionalArgs
   */
  updateProductBuildIDAssets(
    proposalId: string,
    proposalProductId: number,
    data: { id: number; proposal_product_id: number; asset_ids: string },
    params: any,
  ) {
    return http.patch<any>(
      `v1/sales/proposals/${proposalId}/products/${proposalProductId}/build-id`,
      data,
      { params },
    )
  },
  /**
   *
   * @param proposalProductId
   * @param data
   */
  updateSupplementalFields(proposalProductId: number | string, data: any) {
    return http.patch<any>(
      `v1/sales/proposals/${proposalProductId}/supplemental-metadata-update`,
      data,
    )
  },
  /**
   * Fetches buildIDs for a given proposal ID and product ID.
   * @param proposalId - The ID of the proposal
   * @param productId - The ID of the product
   * @param params - Additional parameters (optional)
   */
  getProductBuildIds(proposalId: string, productId: number, params: any) {
    return http.get(`v1/sales/proposals/${proposalId}/products/${productId}/build-id`, { params })
  },
  getBuildIds(proposalId: string, projectId: number, productId: number) {
    return http.get(
      `v1/sales/proposals/${proposalId}/products/project/${projectId}/product/${productId}`,
    )
  },
  /**
   * Fetch print revenue from web service
   * @param params
   * @returns
   */
  fetchPrintRevenue(params: any) {
    return http.get(`v1/proposal_reports/jobs`, { params })
  },
  /**
   * Fetch print revenue from web service until success
   * @param params
   * @returns
   */
  async fetchPrintRevenueUntilSuccess(params: any): Promise<any> {
    const setCacheUpdatedAt = (cacheUpdatedAt: number | null) => {
      if ("printSummary" in params) {
        setCacheUpdatedAtSummary(cacheUpdatedAt)
      } else {
        setCacheUpdatedAtRevenue(cacheUpdatedAt)
      }
    }
    const setLoadingStatus = (loadingStatus: "processing" | "completed" | "failed") => {
      if ("printSummary" in params) {
        setLoadingStatusSummary(loadingStatus)
      } else {
        setLoadingStatusRevenue(loadingStatus)
      }
    }

    const response = await http.get(`v1/proposal_reports/jobs`, { params, showSpinner: false })
    if (response.data.status === "completed") {
      setCacheUpdatedAt(response.data.cache_updated_at)
      setLoadingStatus(response.data.status)
      return response
    } else if (response.data.status === "failed") {
      useGlobalStore.setState(state => ({
        snackbar: {
          ...state.snackbar,
          open: true,
          message: response.data.message,
          severity: "error",
        },
      }))

      setLoadingStatus("failed")
      setCacheUpdatedAt(null)
      return null
    } else {
      setLoadingStatus(response.data.status)
      setCacheUpdatedAt(null)
      await new Promise(resolve => setTimeout(resolve, 2000))
      // force_refresh is used in the first time request
      params.force_refresh = false
      return this.fetchPrintRevenueUntilSuccess(params)
    }
  },
  /**
   * Fetch client options from web service
   */
  fetchClientOptions(all = false) {
    return http.get<ClientOption[]>(`v1/proposal_reports/client-options?all=${all}`)
  },
  /**
   * Fetch buildID updates from web service
   * @param params
   * @returns
   */
  fetchBuildIDUpdates(params: any) {
    return http.get(`v1/proposal_reports/build_id_updates`, { params })
  },
  /**
   * Fetch zipcode from web service
   * @param params
   * @returns
   */
  fetchZipCode(params: any) {
    return http.get(`v1/proposal_reports/zip_code_report`, { params })
  },
  /**
   * Fetch joblogs from web service
   * @param params
   * @returns
   */
  fetchJobLog(params: any) {
    return http.get(`v1/proposal_reports/job_log_report`, { params })
  },
  /**
   * Fetch supplemental revenue data from web service
   * @param params
   * @returns
   */
  fetchSupplementalDetails(params: any) {
    return http.get(`v1/proposal_reports/supplemental_revenue`, { params })
  },
  /**
   * Fetch buildID updates detail from web service
   * @param params buildID id
   * @returns
   */
  fetchBuildIDUpdatesDetail(id: number) {
    return http.get(`v1/proposal_reports/build_ids/${id}`)
  },
  /**
   * Fetch attributes mismatch from web service
   * @param params
   * @returns
   */
  fetchAttributesMismatch(params: any) {
    return http.get<any>(`v1/proposal_reports/assets`, { params })
  },
  /**
   * Fetch modified build ids from web service
   * @param params
   * @returns
   */
  fetchModifiedBuildIDs(params: any) {
    return http.get<any>(`v1/proposal_reports/modified_build_ids`, { params })
  },
  /**
   * Fetch postage reports from web service
   * @param params
   * @returns
   */
  fetchPostageReports(params: any) {
    return http.get<any>(`v1/proposal_reports/postage_report`, { params })
  },
  /**
   * Fetch postage reports groups from web service
   * @param params
   * @returns
   */
  fetchPostageReportsGroups(params: any) {
    return http.get<any>(`v1/proposal_reports/postage_report/groups`, { params })
  },
  /**
   * Fetch build id mapping reports from web service
   * @param params
   * @returns
   */
  fetchBuildIDMappings(params: any) {
    return http.get<any>(`v1/proposal_reports/build_id_mappings`, { params })
  },
  /**
   * Fetch parent client and product options from web service
   */
  fetchParentClientOptions() {
    return http.get<any>(`v1/proposal_reports/parent-client-options`)
  },
  /**
   * Fetch product types from web service
   */
  fetchProductTypes: () => {
    return http.get("/v1/administration_reports/administration_product_types")
  },
  /**
   * Delete product type from web service
   * @param productTypeId Product type primary key
   */
  deleteProductTypes(productTypeId: string) {
    return http.delete<any>(
      `v1/administration_reports/administration_product_types/${productTypeId}`,
    )
  },
  /**
   * Update product type
   * @param productTypeId
   * @param data
   */
  updateProductTypes: (productTypeId: string, data: any) => {
    return http.patch(
      `v1/administration_reports/administration_product_types/${productTypeId}`,
      data,
    )
  },
}
