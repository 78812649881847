import { FC, lazy } from "react"
import { createBrowserRouter, Navigate, redirect, RouteObject } from "react-router-dom"
import lazyLoad from "./lazyLoad"
import { useSessionStore } from "@/stores/session"
import {
  ADMIN_ROLE_ID,
  BETA_ROLE_ID,
  CLIENT_PROFILE_ALL,
  PROPOSAL_ACCOUNT_ID,
  TEAM_MANAGEMENT_ID,
  USER_REPORT_ID,
} from "@/hooks/useRoles"
import { getKPIReportPermission } from "@/pages/BuildID/util"

// redirect to login page if no token
const tokenLoader = () => {
  // get accessToken from session store
  const accessToken = useSessionStore.getState().session?.accessToken
  if (!accessToken) {
    return redirect("/login")
  }
  return null
}

const adminClientLoader = () => {
  // get roles from session store
  const roles = useSessionStore.getState().session?.roles
  const isClientFilter = useSessionStore.getState().session?.isClientFilter
  const isTeamMember = useSessionStore.getState().session?.isTeamMember
  const isAdminRole = roles && roles.some(role => role.role_id === ADMIN_ROLE_ID)
  const isTeamManagementRole = roles && roles.some(role => role.role_id === TEAM_MANAGEMENT_ID)

  if (!(isAdminRole || isClientFilter || isTeamMember || isTeamManagementRole)) {
    return redirect("/index")
  }
  return null
}

const betaLoader = () => {
  // get roles from session store
  const roles = useSessionStore.getState().session?.roles
  const isBetaRole = roles && roles.some(role => role.role_id === BETA_ROLE_ID)
  if (!isBetaRole) {
    return redirect("/index")
  }
  return null
}

/**
 * Loader to control whether user can access Accounts, Opportunities, and Contacts
 */
const accountLoader = () => {
  // get roles from session store
  const roles = useSessionStore.getState().session?.roles
  const isProposalAccountRole = roles && roles.some(role => role.role_id === PROPOSAL_ACCOUNT_ID)
  if (!isProposalAccountRole) {
    return redirect("/index")
  }
  return null
}

const accountDetailLoader = ({ params }: any) => {
  // get roles from session store
  const roles = useSessionStore.getState().session?.roles
  const isProposalAccountRole = roles && roles.some(role => role.role_id === PROPOSAL_ACCOUNT_ID)

  const { id, accessible } = useSessionStore.getState().session?.accountAccess ?? {
    id: "",
    accessible: false,
  }
  const canVisible = id === params?.id && accessible
  if (canVisible) return null
  if (!isProposalAccountRole) {
    return redirect("/index")
  }
  return null
}

const clientProfileAllLoader = () => {
  // get roles from session store
  const roles = useSessionStore.getState().session?.roles
  const isAdminRole = roles && roles.some(role => role.role_id === ADMIN_ROLE_ID)
  const isClientProfileAllRole = roles && roles.some(role => role.role_id === CLIENT_PROFILE_ALL)
  if (!(isAdminRole || isClientProfileAllRole)) {
    return redirect("/index")
  }
  return null
}

const usersLoader = () => {
  // get roles from session store
  const roles = useSessionStore.getState().session?.roles
  const isAdminRole = roles && roles.some(role => role.role_id === ADMIN_ROLE_ID)
  const isUserReportRole = roles && roles.some(role => role.role_id === USER_REPORT_ID)
  if (!isAdminRole && !isUserReportRole) {
    return redirect("/index")
  }
  return null
}

const adminLoader = () => {
  // get roles from session store
  const roles = useSessionStore.getState().session?.roles
  const isAdminRole = roles && roles.some(role => role.role_id === ADMIN_ROLE_ID)
  if (!isAdminRole) {
    return redirect("/index")
  }
  return null
}

// const surveyLoader = () => {
//   const {roles, teams} = useSessionStore.getState().session || {}
//   const isAdminRole = roles && roles?.some?.(role => role?.role_id === ADMIN_ROLE_ID)
//   const isHRASurveyteam = teams && teams?.some?.(team => team?.team_name === Survey_Team_Name)
//   if(!isAdminRole && !isHRASurveyteam) {
//     return redirect("/index")
//   }
//   return null
// }

const routes: RouteObject[] = [
  {
    path: "/login",
    element: lazyLoad(lazy(() => import("@/pages/Login"))),
  },
  {
    path: "/sso-redirect/:token",
    element: lazyLoad(lazy(() => import("@/pages/Login/SsoRedirect"))),
  },
  {
    path: "/",
    element: lazyLoad(lazy(() => import("@/layout"))),
    loader: tokenLoader,
    children: [
      {
        index: true,
        element: <Navigate to="/index" replace />,
      },
      {
        path: "index",
        element: lazyLoad(lazy(() => import("@/pages/Home"))),
        handle: {
          title: "Print Jobs",
        },
      },
      {
        path: "user-management",
        loader: usersLoader,
        element: lazyLoad(lazy(() => import("@/pages/UserManagement"))),
        handle: {
          title: "Users",
        },
      },
      {
        path: "admin-management",
        loader: adminLoader,
        element: lazyLoad(lazy(() => import("@/pages/Admin/Manage"))),
        handle: {
          title: "Admin Manage",
        },
      },
      {
        path: "alert-setting",
        loader: adminClientLoader,
        element: lazyLoad(lazy(() => import("@/pages/Alert"))),
        handle: { title: "Alert Settings" },
      },
      {
        path: "help",
        element: lazyLoad(lazy(() => import("@/pages/Help"))),
        children: [
          {
            index: true,
            element: <Navigate to="/help/user-guide" replace />,
          },
          {
            path: "user-guide",
            element: lazyLoad(lazy(() => import("@/pages/Help/UserGuide"))),
            handle: {
              hiddenQuickSearch: true,
              title: "User Guide",
            },
          },
          {
            path: "release-notes",
            element: lazyLoad(lazy(() => import("@/pages/Help/ReleaseNotes"))),
            handle: {
              hiddenQuickSearch: true,
              title: "Release Notes",
            },
          },
        ],
      },
      {
        path: "survey",
        element: lazyLoad(lazy(() => import("@/pages/Survey/layout"))),
        // loader: surveyLoader,
        children: [
          {
            index: true,
            element: <Navigate to="survey-scans" replace />,
          },
          {
            path: "survey-scans",
            element: lazyLoad(lazy(() => import("@/pages/Survey/SurveyScans/layout"))),
            children: [
              {
                index: true,
                element: <Navigate to="list" replace />,
              },
              {
                path: "list",
                element: lazyLoad(lazy(() => import("@/pages/Survey/SurveyScans/List"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "Survey Scans List",
                },
              },
              {
                path: "data-graph",
                element: lazyLoad(lazy(() => import("@/pages/Survey/SurveyScans/DataGraph"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "Survey Scans Data Graph",
                },
              },
            ],
          },
          {
            path: "digital-surveys",
            element: lazyLoad(lazy(() => import("@/pages/Survey/DigitalSurveys"))),
            handle: {
              hiddenQuickSearch: true,
              title: "Digital Surveys",
            },
            children: [
              {
                index: true,
                element: <Navigate to="list" replace />,
              },
              {
                path: "list",
                element: lazyLoad(lazy(() => import("@/pages/Survey/DigitalSurveys/List"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "Survey Scans List",
                },
              },
              {
                path: "data-graph",
                element: lazyLoad(lazy(() => import("@/pages/Survey/DigitalSurveys/DataGraph"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "Survey Scans Data Graph",
                },
              },
            ],
          },
          {
            path: "template",
            element: lazyLoad(lazy(() => import("@/pages/Survey/Template/layout"))),
            handle: {
              hiddenQuickSearch: true,
              title: "Template",
            },
            children: [
              {
                index: true,
                element: <Navigate to="list" replace />,
              },
              {
                path: "list",
                element: lazyLoad(lazy(() => import("@/pages/Survey/Template/List"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "Template List",
                },
              },
              {
                path: "edit/:id?",
                element: lazyLoad(lazy(() => import("@/pages/Survey/Template/Edit"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "Template Edit",
                },
              },
            ],
          },
          {
            path: "billing-report",
            element: lazyLoad(lazy(() => import("@/pages/Survey/BillingReport"))),
            handle: {
              hiddenQuickSearch: true,
              title: "Survey Report",
            },
            children: [
              {
                index: true,
                element: <Navigate to="list" replace />,
              },
              {
                path: "list",
                element: lazyLoad(lazy(() => import("@/pages/Survey/BillingReport/List"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "Survey Report List",
                },
              },
              {
                path: "data-graph",
                element: lazyLoad(lazy(() => import("@/pages/Survey/BillingReport/DataGraph"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "Survey Report Data Graph",
                },
              },
            ],
          },
        ],
      },
      {
        path: "worklog",
        element: lazyLoad(lazy(() => import("@/pages/Worklog/layout"))),
        children: [
          {
            index: true,
            element: <Navigate to="resource" replace />,
          },
          {
            path: "resource",
            element: lazyLoad(lazy(() => import("@/pages/Worklog/Resource"))),
            handle: {
              hiddenQuickSearch: true,
              title: "Worklog",
            },
          },
          {
            path: "project",
            element: lazyLoad(lazy(() => import("@/pages/Worklog/Project"))),
            handle: {
              hiddenQuickSearch: true,
              title: "Worklog",
            },
          },
        ],
      },
      {
        path: "teams",
        element: lazyLoad(lazy(() => import("@/pages/TeamManagement"))),
        handle: {
          title: "Team Management",
        },
      },
      {
        path: "teams/:id",
        element: lazyLoad(lazy(() => import("@/pages/TeamManagement/Detail"))),
        handle: {
          hiddenQuickSearch: true,
          title: "Team Management",
        },
      },
      {
        path: "client-profile",
        element: lazyLoad(lazy(() => import("@/pages/ClientProfile"))),
        handle: {
          title: "Client Profile",
        },
        children: [
          {
            path: ":id",
            element: lazyLoad(lazy(() => import("@/pages/ClientProfile/ClientProfilePage"))),
            handle: {
              title: "Client Profile",
            },
          },
          {
            path: ":id/product/:productId",
            element: lazyLoad(lazy(() => import("@/pages/ClientProfile/ClientProductPage"))),
            handle: {
              title: "Client Profile",
            },
          },
        ],
      },
      {
        path: "client-profile-all",
        loader: clientProfileAllLoader,
        element: lazyLoad(lazy(() => import("@/pages/ClientProfile/ClientTablePage"))),
        handle: {
          title: "All Client Profile",
        },
      },
      {
        path: "forecast",
        element: lazyLoad(lazy(() => import("@/pages/Forecast/layout"))),
        loader: betaLoader,
        children: [
          {
            index: true,
            element: <Navigate to="client" replace />,
          },
          {
            path: "client",
            element: lazyLoad(lazy(() => import("@/pages/Forecast/ClientForecast"))),
            handle: {
              hiddenQuickSearch: true,
              title: "Client",
            },
          },
          {
            path: "inventory",
            element: lazyLoad(lazy(() => import("@/pages/Forecast/InventoryForecast"))),
            handle: {
              hiddenQuickSearch: true,
              title: "Inventory",
            },
          },
          {
            path: "machine",
            element: lazyLoad(lazy(() => import("@/pages/Forecast/MachineForecast"))),
            handle: {
              hiddenQuickSearch: true,
              title: "Machine",
            },
          },
        ],
      },
      {
        path: "inventory",
        element: lazyLoad(lazy(() => import("@/pages/Inventory"))),
        handle: {
          hiddenQuickSearch: true,
          title: "Inventory",
        },
      },
      {
        path: "inventory/:id/:locationCode/:itemDesc",
        element: lazyLoad(lazy(() => import("@/pages/Inventory/Detail"))),
        handle: {
          hiddenQuickSearch: true,
          title: "Inventory",
        },
      },
      {
        path: "proposal-activation",
        element: lazyLoad(lazy(() => import("@/pages/ProposalSetup/layout"))),
        children: [
          {
            index: true,
            element: <Navigate to="proposals" replace />,
          },
          {
            path: "proposals",
            element: lazyLoad(
              lazy(() => import("@/pages/ProposalSetup/Proposal") as Promise<{ default: FC<any> }>),
            ),
            handle: {
              title: "Proposal Setup",
            },
          },
          {
            path: "proposals/:id",
            element: lazyLoad(lazy(() => import("@/pages/ProposalSetup/Proposal/Detail"))),
            handle: { hiddenQuickSearch: true, title: "Proposal" },
          },
          {
            path: "accounts",
            loader: accountLoader,
            element: lazyLoad(lazy(() => import("@/pages/ProposalSetup/Account"))),
            handle: {
              title: "CRM Accounts",
            },
          },
          {
            path: "accounts/:id",
            loader: accountDetailLoader,
            element: lazyLoad(lazy(() => import("@/pages/ProposalSetup/Account/Detail"))),
            handle: { hiddenQuickSearch: true, title: "CRM Account" },
          },
          {
            path: "crm-proposals",
            element: lazyLoad(
              lazy(
                () => import("@/pages/ProposalSetup/CRMProposal") as Promise<{ default: FC<any> }>,
              ),
            ),
            handle: {
              title: "CRM Proposals",
            },
          },
          {
            path: "crm-proposals/:id",
            loader: accountLoader,
            element: lazyLoad(lazy(() => import("@/pages/ProposalSetup/CRMProposal/Detail"))),
            handle: { hiddenQuickSearch: true, title: "CRM Proposal" },
          },
          {
            path: "opportunities",
            loader: accountLoader,
            element: lazyLoad(lazy(() => import("@/pages/ProposalSetup/Opportunity"))),
            handle: {
              title: "CRM Opportunities",
            },
          },
          {
            path: "opportunities/:id",
            loader: accountLoader,
            element: lazyLoad(lazy(() => import("@/pages/ProposalSetup/Opportunity/Detail"))),
            handle: { hiddenQuickSearch: true, title: "CRM Opportunity" },
          },
          {
            path: "contacts",
            loader: accountLoader,
            element: lazyLoad(lazy(() => import("@/pages/ProposalSetup/Contact"))),
            handle: {
              title: "CRM Contacts",
            },
          },
          {
            path: "contacts/:id",
            loader: accountLoader,
            element: lazyLoad(lazy(() => import("@/pages/ProposalSetup/Contact/Detail"))),
            handle: { hiddenQuickSearch: true, title: "CRM Contact" },
          },
          {
            path: "reports",
            element: lazyLoad(lazy(() => import("@/pages/ProposalSetup/Report"))),
            handle: { hiddenQuickSearch: true, title: "Proposal Reports" },
          },
          {
            path: "administration",
            element: lazyLoad(lazy(() => import("@/pages/ProposalSetup/Administration"))),
            handle: { hiddenQuickSearch: true, title: "Administration" },
          },
        ],
      },
      {
        path: "user-profile",
        element: lazyLoad(lazy(() => import("@/pages/UserProfile"))),
        handle: {
          hiddenQuickSearch: true,
          title: "User Profile",
        },
      },
      {
        path: "preference-settings",
        element: lazyLoad(lazy(() => import("@/pages/Preference"))),
        handle: {
          hiddenQuickSearch: true,
          title: "Preference Settings",
        },
      },
      {
        path: "message-center",
        element: lazyLoad(lazy(() => import("@/pages/MessageCenter/index"))),
        children: [
          {
            index: true,
            element: lazyLoad(
              lazy(() => import("@/pages/MessageCenter/Components/EventsView/index")),
            ),
            handle: {
              title: "Message Center",
            },
          },
        ],
      },
      {
        path: "ai-chat",
        element: lazyLoad(lazy(() => import("@/pages/AIChat"))),
        handle: {
          title: "AI Chat",
        },
      },
      {
        path: "sla-report",
        element: lazyLoad(lazy(() => import("@/pages/SLAReport"))),
        children: [
          {
            index: true,
            element: <Navigate to="job/details" replace />,
          },
          {
            path: "job",
            element: lazyLoad(lazy(() => import("@/pages/SLAReport/Job"))),
            children: [
              {
                index: true,
                element: <Navigate to="job/details" replace />,
              },
              {
                path: "details",
                element: lazyLoad(lazy(() => import("@/pages/SLAReport/Job/Details"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "SLA Job",
                },
              },
              {
                path: "summary",
                element: lazyLoad(lazy(() => import("@/pages/SLAReport/Job/Summary"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "SLA Job",
                },
              },
            ],
          },
          {
            path: "adherence",
            element: lazyLoad(lazy(() => import("@/pages/SLAReport/Adherence"))),
            children: [
              {
                index: true,
                element: <Navigate to="adherence/list" replace />,
              },
              {
                path: "list",
                element: lazyLoad(lazy(() => import("@/pages/SLAReport/Adherence/List"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "SLA Adherence",
                },
              },
              {
                path: "data-graph",
                element: lazyLoad(lazy(() => import("@/pages/SLAReport/Adherence/DataGraph"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "SLA Adherence",
                },
              },
            ],
          },
        ],
      },
      {
        path: "reconciliation-report",
        element: lazyLoad(lazy(() => import("@/pages/ReconciliationReport"))),
        handle: {
          hiddenQuickSearch: true,
          title: "Reconciliation Report",
        },
      },
      {
        path: "admin-logs",
        element: lazyLoad(lazy(() => import("@/pages/Admin/Log"))),
        handle: {
          title: "Admin Logs",
        },
      },
      {
        path: "ai-assistants",
        element: lazyLoad(lazy(() => import("@/pages/AIAssistants"))),
        handle: {
          title: "AI Assistants",
        },
      },
      {
        path: "ai-assistants/:id",
        element: lazyLoad(lazy(() => import("@/pages/AIAssistants/CreateAIBot"))),
        handle: {
          title: "AI Assistants",
        },
      },
      {
        path: "cpa-tickets",
        element: lazyLoad(lazy(() => import("@/pages/CPATickets/layout"))),
        children: [
          {
            index: true,
            element: <Navigate to="summary" replace />,
          },
          {
            path: "details",
            element: lazyLoad(lazy(() => import("@/pages/CPATickets/Details"))),
            handle: {
              hiddenQuickSearch: true,
              title: "CPA Tickets",
            },
          },
          {
            path: "summary",
            element: lazyLoad(lazy(() => import("@/pages/CPATickets/Summary"))),
            handle: {
              hiddenQuickSearch: true,
              title: "CPA Tickets",
            },
          },
        ],
      },
      {
        path: "buildid",
        element: null,
        loader: ({ request }) => {
          // Compatible with previous router
          const index = request.url.indexOf("?")
          if (index !== -1) {
            if (request.url.includes("tab=all_buildids")) {
              return redirect("/build-id/all")
            }
            return redirect(`/build-id/approval${request.url.slice(index)}`)
          }
          return redirect("/build-id/approval")
        },
      },
      {
        path: "build-id",
        element: lazyLoad(lazy(() => import("@/pages/BuildID"))),
        handle: {
          hiddenQuickSearch: true,
        },
        children: [
          {
            index: true,
            element: <Navigate to="approval" replace />,
          },
          {
            path: "approval",
            element: lazyLoad(lazy(() => import("@/pages/BuildID/BuildIDApproval"))),
            handle: {
              hiddenQuickSearch: true,
              title: "BuildID Approvals",
            },
          },
          {
            path: "all",
            element: lazyLoad(lazy(() => import("@/pages/BuildID/AllBuildIDs"))),
            handle: {
              title: "BuildIDs",
            },
          },
          {
            path: "kpi-report",
            element: lazyLoad(lazy(() => import("@/pages/BuildID/Report"))),
            loader: () => {
              const session = useSessionStore.getState().session
              if (!getKPIReportPermission(session)) {
                return redirect("/build-id/approval")
              }
              return null
            },
            handle: {
              title: "BuildID Report",
            },
            children: [
              {
                index: true,
                element: <Navigate to="list" replace />,
              },
              {
                path: "list",
                element: lazyLoad(lazy(() => import("@/pages/BuildID/Report/List"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "BuildID Report List",
                },
              },
              {
                path: "data-graph",
                element: lazyLoad(lazy(() => import("@/pages/BuildID/Report/DataGraph"))),
                handle: {
                  hiddenQuickSearch: true,
                  title: "BuildID KPI Report",
                },
              },
            ],
          },
          // {
          //   path: "report",
          //   element: lazyLoad(lazy(() => import("@/pages/BuildID/Report"))),
          //   handle: {
          //     title: "BuildID Report",
          //   },
          // },
        ],
      },
      {
        path: "client-datafiles",
        element: lazyLoad(lazy(() => import("@/pages/ClientDataFiles/layout"))),
        loader: betaLoader,
        handle: {
          title: "Client Datafiles Details",
        },
        children: [
          {
            index: true,
            element: <Navigate to="details" replace />,
          },
          {
            path: "details",
            element: lazyLoad(lazy(() => import("@/pages/ClientDataFiles/Details"))),
            handle: {
              hiddenQuickSearch: true,
              title: "Client Datafiles Details",
            },
          },
          {
            path: "summary",
            element: lazyLoad(lazy(() => import("@/pages/ClientDataFiles/Summary"))),
            handle: {
              hiddenQuickSearch: true,
              title: "Client Datafiles Summary",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/pdf-viewer",
    element: lazyLoad(lazy(() => import("@/pages/PDFViewer"))),
  },
  {
    path: "*",
    element: <Navigate to="/index" />,
  },
]

const router = createBrowserRouter(routes, {
  basename: import.meta.env.VITE_ROUTER_BASENAME,
})

export default router
