import { useSessionStore } from "@/stores/session"
import { useGlobalStore } from "@/stores/global"

export const handleHttpErrorTools = (error: any, resetSession = true) => {
  let message = error.message || "Unknown Error"

  // if request is canceled, ignore the error
  if (message === "canceled") return

  // If there are response data from http request, append the data to the message
  if (error.response?.data) {
    message = `${message}: ${error.response.data?.message ?? error.response.data}`
  }

  switch (error.response?.status) {
    case 401:
      if (resetSession) {
        useSessionStore.getState().reset()
        const { pathname, search } = window.location
        window.location.href = `/login?redirect=${pathname}${encodeURIComponent(search)}`
      } else {
        useGlobalStore.setState(state => ({
          snackbar: {
            ...state.snackbar,
            open: true,
            message: "Unauthorized",
            severity: "error",
          },
        }))
      }
      break
    // Set the new Server Version flag for a modal dialog to appear for the page to be refreshed.
    // Clear the session, reset version number, and force load browser if upgrade required.
    case 426:
      useSessionStore.setState(state => ({
        session: { ...state.session, newServerVersion: true },
      }))
      break
    // Chat Http Server
    case 422:
      useGlobalStore.setState(state => ({
        snackbar: {
          ...state.snackbar,
          open: true,
          message: error.response?.data?.message ?? error.response?.statusText,
          severity: "error",
        },
      }))
      break
    default:
      if (error.config?.url === "/v1/session") {
        message = error.response
          ? error.response.data
          : "Could not authenticate you from Ldapmain because 'Network timeout issue'."
      }
      useGlobalStore.setState(state => ({
        snackbar: {
          ...state.snackbar,
          open: true,
          message: message,
          severity: "error",
        },
      }))
      break
  }
}
