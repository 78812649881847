import dayjs from "dayjs"
import { create } from "zustand"
import { GridFilterModel } from "@mui/x-data-grid"

const initialState = {
  yearMonth: dayjs().subtract(1, "month") as dayjs.Dayjs | null,
  client: {
    activated: false,
    project_id: -1,
    project_name: "",
    products: [],
  } as any,
  product: [] as any[],
  proposal: {
    proposal_name: "All",
    proposal_number: "All",
  } as any,
  generateReport: null as null | object,
  filterModel: undefined as GridFilterModel | undefined,
  loadingStatus: null as null | "processing" | "completed" | "failed",
  cache_updated_at: null as null | number,
}

export const usePrintSummaryStore = create<typeof initialState>()(() => initialState)

export const setYearMonth = (yearMonth: (typeof initialState)["yearMonth"]) =>
  usePrintSummaryStore.setState({ yearMonth })

export const setClient = (client: (typeof initialState)["client"]) =>
  usePrintSummaryStore.setState({ client })

export const setProduct = (product: (typeof initialState)["product"]) =>
  usePrintSummaryStore.setState({ product })

export const setProposal = (proposal: (typeof initialState)["proposal"]) =>
  usePrintSummaryStore.setState({ proposal })

export const setGenerateReport = (generateReport: (typeof initialState)["generateReport"]) =>
  usePrintSummaryStore.setState({ generateReport })

export const reset = () => usePrintSummaryStore.setState(initialState)

export const setFilterModel = (filterModel: (typeof initialState)["filterModel"]) => {
  usePrintSummaryStore.setState({ filterModel })
}

export const setLoadingStatus = (loadingStatus: (typeof initialState)["loadingStatus"]) => {
  usePrintSummaryStore.setState({ loadingStatus })
}

export const setCacheUpdatedAt = (cacheUpdatedAt: (typeof initialState)["cache_updated_at"]) => {
  usePrintSummaryStore.setState({ cache_updated_at: cacheUpdatedAt })
}
