import http from "@/utils/http"
import { PreferencesDetail } from "./types"

export const personalizationApi = {
  /**
   * Login with given credential payload from session service
   */
  setPersonalization(params: PreferencesDetail) {
    return http.post<PreferencesDetail>("/v1/personalization", params, { showSpinner: false })
  },
}
