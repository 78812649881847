import { create } from "zustand"
import {
  HighContrastPreference,
  NotificationPreference,
  ShowClientLogoPreference,
  ThemeModePreference,
  TimezonePreference,
  type PreferenceChoiceTheme,
} from "@/types/preference"
import { isValidTimezoneValue } from "@/utils"
import { getPreference } from "@/utils/preference"

// ---------- Timezone --------------
// Get the current timezone from the preference
let timezone = getPreference(TimezonePreference.PAGE, TimezonePreference.FIELD)?.value || "System"
// Check if the timezone is valid, if not, set it to system
timezone = isValidTimezoneValue(timezone) ? timezone : "System"

// ---------- highContrast --------------
// Get the current high contrast preference from the preference
const highContrast = getPreference(HighContrastPreference.PAGE, HighContrastPreference.FIELD)?.value

// ---------- displayLogo --------------
// Get the current show client logo preference from the preference
const displayLogo = getPreference(
  ShowClientLogoPreference.PAGE,
  ShowClientLogoPreference.FIELD,
)?.value

// ---------- theme --------------
// Get the current theme preference from the preference
const theme = getPreference(ThemeModePreference.PAGE, ThemeModePreference.FIELD)?.value

// ---------- notificationMethod --------------
// Get the current theme preference from the preference
const notificationMethod =
  getPreference(NotificationPreference.PAGE, NotificationPreference.FIELD)?.value ?? "email"

const initialState = {
  timezone,
  highContrast: highContrast !== "false",
  displayLogo: displayLogo !== "false",
  theme: (theme || "system") as PreferenceChoiceTheme,
  notificationMethod,
}

export const useSettingStore = create<typeof initialState>()(() => initialState)

export const setTimezone = (timezone: string) => useSettingStore.setState({ timezone })

export const setHighContrast = (highContrast: boolean) => useSettingStore.setState({ highContrast })

export const setDisplayLogo = (displayLogo: boolean) => useSettingStore.setState({ displayLogo })

export const setTheme = (theme: PreferenceChoiceTheme) => useSettingStore.setState({ theme })

export const setNotificationMethod = (notificationMethod: string) =>
  useSettingStore.setState({ notificationMethod })
