import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from "axios"
import axios from "axios"
import { useSessionStore } from "@/stores/session"
import { useGlobalStore } from "@/stores/global"
import { handleHttpErrorTools } from "./httpErrorTools"
import { getLocalStorageToken } from "."
import { getTimezone } from "./date"

declare module "axios" {
  export interface AxiosRequestConfig {
    showSpinner?: boolean
  }
}

class Request {
  private instance: AxiosInstance
  private spinnerNum = 0
  private abortControllerMap: Map<string, AbortController>

  constructor(config?: CreateAxiosDefaults, resetSession = true) {
    this.instance = axios.create(config)
    // Initializes the Map that holds the cancel request controller
    this.abortControllerMap = new Map()
    // Pre-request interception, handling some specific logic, including showing request indicators and adding
    // the authentication token to the request header.
    this.instance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        // show Spinner indicators
        if (config.showSpinner !== false) {
          useGlobalStore.getState().spinnerNumIncrement()
        }

        if (config.url !== "/v1/session") {
          const session = useSessionStore.getState().session

          const token = getLocalStorageToken()
          if (token) {
            config.headers["x-auth"] = token // add token data to header for each request after login successfully
          }

          const version = session?.serverVersion
          if (version) {
            config.headers["Access-Control-Expose-Headers"] = "version"
            config.headers["version"] = version // Add the version to the request if we have a version
          }

          config.headers["timezone"] = getTimezone() // Add the timezone to the request
        }

        // if the request does not have a signal, create a new one and store it
        if (!config.signal) {
          const controller = new AbortController()
          config.signal = controller.signal
          this.abortControllerMap.set(config.url || "", controller)
        }

        return config
      },
      err => {
        return Promise.reject(err)
      },
    )

    // Handles the app specific logic for responding to an ajax response, including hiding ajax indicators
    // addressing any non 200 statuses from the response.
    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.config.showSpinner !== false) {
          useGlobalStore.getState().spinnerNumDecrement()
        }

        // After the request is successful, the storage cancel request controller is deleted
        this.abortControllerMap.delete(response.config.url || "")

        return response
      },
      error => {
        if (error.config?.showSpinner !== false) {
          useGlobalStore.getState().spinnerNumDecrement()
        }
        // handling failed requests
        handleHttpErrorTools(error, resetSession)
        // return err
        return Promise.reject(error)
      },
    )
  }

  /**
   * Cancels the specified request
   * @param url The request URL to cancel
   */
  cancelRequest(url: string | string[]) {
    const urlList = Array.isArray(url) ? url : [url]
    for (const _url of urlList) {
      this.abortControllerMap.get(_url)?.abort()
      this.abortControllerMap.delete(_url)
    }
  }

  request<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.request(config)
  }

  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.get(url, config)
  }

  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.delete(url, config)
  }

  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.post(url, data, config)
  }

  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.patch(url, data, config)
  }

  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.put(url, data, config)
  }
}

const http = new Request({
  timeout: 1000 * 60 * 5,
  // @ts-ignore
  baseURL: window.env?.VITE_API_BASE_URL || import.meta.env.VITE_API_BASE_URL,
})

export default http
export const msgCtrHttp = new Request(
  {
    timeout: 1000 * 60 * 5,
    // @ts-ignore
    baseURL: window.env?.VITE_MSGCTR_API_BASE_URL || import.meta.env.VITE_MSGCTR_API_BASE_URL,
  },
  false,
)

export const chatHttp = new Request(
  {
    timeout: 1000 * 60 * 5,
    // @ts-ignore
    baseURL: window.env?.VITE_CHAT_API_BASE_URL || import.meta.env.VITE_CHAT_API_BASE_URL,
  },
  false,
)
