import { Alert, AlertTitle, Slide, SlideProps, Snackbar } from "@mui/material"
import { useGlobalStore } from "@/stores/global"

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />
}

const GlobalSnackbar = () => {
  const snackbar = useGlobalStore(state => state.snackbar)
  const updateSnackbar = useGlobalStore(state => state.updateSnackbar)

  /**
   * hidden snackbar
   */
  const handleClose = () => {
    updateSnackbar({ open: false })
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: snackbar.vertical,
        horizontal: snackbar.horizontal,
      }}
      open={snackbar.open}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
    >
      <Alert severity={snackbar.severity || "error"} sx={{ width: "100%" }}>
        {snackbar.title && <AlertTitle>{snackbar.title}</AlertTitle>}
        {snackbar.message}
      </Alert>
    </Snackbar>
  )
}

export default GlobalSnackbar
