import ReactDOM from "react-dom/client"
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import "rc-tree/assets/index.css"
import "./styles/variables.css"
import "./styles/index.scss"

const environment = import.meta.env.VITE_APP_ENV
if(environment !== "development") {
  Sentry.init({
    environment: environment,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />)
