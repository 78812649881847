import { RouterProvider } from "react-router-dom"
import { useGlobalStore } from "./stores/global"
import { useSessionStore } from "./stores/session"
import router from "./router"
import Snackbar from "./components/GlobalSnackbar"
import BigSpinner from "./components/BigSpinner"
import NiceModal from "@ebay/nice-modal-react"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import { CssBaseline, createTheme } from "@mui/material"
import { getDesignTokens } from "./styles/mui/theme"
import { useMemo, useEffect } from "react"
import { ThemeMode } from "./types/config"
import { useSettingStore } from "./stores/setting"

const Spin = () => {
  const showSpinner = useGlobalStore(state => state.showSpinner)

  if (!showSpinner) return null

  return <BigSpinner />
}

const NewVersion = () => {
  const session = useSessionStore(state => state.session)

  if (session?.newServerVersion) {
    // Just reload the page when server version got updated
    window.location.reload()
  }
  if (session?.newUIVersion) {
    useSessionStore.setState(state => ({
      session: { ...state.session, newUIVersion: false },
    }))
    window.location.reload()
  }

  return null
}

function App() {
  const themeMode = useGlobalStore(state => state.themeMode)
  const setThemeMode = useGlobalStore(state => state.setThemeMode)
  const theme = useMemo(() => createTheme(getDesignTokens(themeMode)), [themeMode])
  const className = themeMode === ThemeMode.Dark ? "dark" : "light"

  const preferredTheme = useSettingStore(state => state.theme)

  useEffect(() => {
    const followSystemTheme = function (event: MediaQueryListEvent) {
      const isDarkMode = event.matches
      setThemeMode(isDarkMode ? ThemeMode.Dark : ThemeMode.Light)
    }
    /* when preferred theme changes, change theme accordingly */
    if (preferredTheme === "system") {
      const eventTarget = window.matchMedia("(prefers-color-scheme: dark)")
      // set theme mode according to system theme
      eventTarget.matches ? setThemeMode(ThemeMode.Dark) : setThemeMode(ThemeMode.Light)
      // add listener to system theme change
      eventTarget.addEventListener("change", followSystemTheme)
      return () => {
        eventTarget.removeEventListener("change", followSystemTheme)
      }
    } else {
      setThemeMode(preferredTheme as ThemeMode)
    }
  }, [preferredTheme, setThemeMode])

  useEffect(() => {
    const root = window.document.documentElement
    root.classList.remove("dark", "light")
    root.classList.add(className)
  }, [className])

  return (
    <div className={`${className} bg-10-80`} key={themeMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NiceModal.Provider>
          <RouterProvider router={router} />
          <Spin />
          <Snackbar />
          <NewVersion />
        </NiceModal.Provider>
      </ThemeProvider>
    </div>
  )
}

export default App
