import { Box } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFan } from "@fortawesome/free-solid-svg-icons"

const BigSpinner = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        inset: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#00000080",
        zIndex: 2000,
        color: "#478bff",
      }}
    >
      <FontAwesomeIcon icon={faFan} spin size="7x" style={{ opacity: 0.5 }} />
    </Box>
  )
}

export default BigSpinner
