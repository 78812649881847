import { GridFilterModel } from "@mui/x-data-grid"
import dayjs from "dayjs"

export const LAYOUT_TABS = [
  { label: "BuildID Approvals", value: "/build-id/approval" },
  { label: "BuildIDs", value: "/build-id/all" },
  { label: "Report", value: "/build-id/kpi-report/list", path: "/build-id/kpi-report" },
]

export const buildIDApprovalsTab = LAYOUT_TABS[0].value

export const QUICK_FILTER_OPTIONS = [
  { label: "Pending My Approval", value: "pending" },
  { label: "Show All", value: "all" },
  // { label: "Rejected", value: "rejected" },
  // { label: "Approved", value: "approved" },
]

export enum AttributesViewEnum {
  ALL = "all",
  UPDATED = "updated",
}

export const STATUS_COLOR_MAP = new Map([
  ["pending", "#FA8C16"],
  ["approved", "#52C41A"],
  ["rejected", "#FF4D4F"],
])

export const DIFF_COLUMNS_WIDTH = [9, 9, 6]

export const APPROVAL_ICON_COLOR_MAP = new Map([
  ["pending", { color: "#FA8C16", bgColor: "#FA8C1629" }],
  ["approved", { color: "#2E7D32", bgColor: "#2E7D3229" }],
  ["rejected", { color: "#FF5658", bgColor: "#FF565829" }],
])

export const PRODUCT_TYPE_ORDER: Record<string, number> = {
  PB: 0,
  Cover: 1,
  AD: 2,
  PC: 3,
  ID: 4,
  CHK: 5,
  LT: 6,
  SS: 7,
  ENV: 8,
  OTH: 9,
}

const filterFnMap: any = {
  isEmpty: (target: any[], field: string) => target.filter(item => item[field] === ""),
  isNotEmpty: (target: any[], field: string) => target.filter(item => item[field] !== ""),
  contains: (target: any[], field: string, value: string) =>
    target.filter(row => {
      const val = row[field]
      if (typeof val === "string") {
        return val.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      } else if (typeof val === "number") {
        return val.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
      }
      return false
    }),
  notContains: (target: any[], field: string, value: string) =>
    target.filter(row => {
      const val = row[field]
      if (typeof val === "string") {
        return !val.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      } else if (typeof val === "number") {
        return !val.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
      }
      return false
    }),
  equals: (target: any[], field: string, value: string) =>
    target.filter(row => row[field] == value),
  startsWith: (target: any[], field: string, value: string) =>
    target.filter(row => row[field]?.toString().startsWith(value)),
  endsWith: (target: any[], field: string, value: string) =>
    target.filter(row => row[field]?.toString().endsWith(value)),
  is: (target: any[], field: string, value: string) => target.filter(row => row[field] === value),
  isNot: (target: any[], field: string, value: string) =>
    target.filter(row => row[field] !== value),
  on: (target: any[], field: string, value: string) =>
    target.filter(row => dayjs(row[field]).isSame(dayjs(value), "day")),
  notOn: (target: any[], field: string, value: string) =>
    target.filter(row => !dayjs(row[field]).isSame(dayjs(value), "day")),
  before: (target: any[], field: string, value: string) =>
    target.filter(row => dayjs(row[field]).isBefore(dayjs(value))),
  onOrBefore: (target: any[], field: string, value: string) =>
    target.filter(row => {
      const val = row[field]
      return dayjs(row[field]).isSame(dayjs(value), "day") || dayjs(val).isBefore(dayjs(value))
    }),
  after: (target: any[], field: string, value: string) =>
    target.filter(row => dayjs(row[field]).isAfter(dayjs(value), "day")),
  onOrAfter: (target: any[], field: string, value: string) =>
    target.filter(row => {
      const val = row[field]
      return dayjs(row[field]).isSame(dayjs(value), "day") || dayjs(val).isAfter(dayjs(value))
    }),
}

export const handleFilterRows = (rows: any[], filterModel?: GridFilterModel) => {
  if (!filterModel) return rows
  if (!filterModel.items) return rows

  let filteredRows: any[] = [...rows]
  filterModel.items.forEach(item => {
    const { field, value, operator } = item
    if (filterFnMap[operator]) {
      filteredRows = filterFnMap[operator](filteredRows, field, value)
    }
  })
  return filteredRows
}
