import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { getTimeZones } from "@vvo/tzdb"
import { useSettingStore } from "@/stores/setting"

dayjs.extend(utc)
dayjs.extend(timezone)

export const dayjsUtc = dayjs

type TimeZoneNameType =
  | "long"
  | "short"
  | "shortOffset"
  | "longOffset"
  | "shortGeneric"
  | "longGeneric"
  | undefined

export const getTimezone = () => {
  let timezone = useSettingStore.getState().timezone
  if (timezone === "System") {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    // timezone = dayjs.tz.guess()
  }
  return timezone
}

export const getTimeZoneName = (timeZone?: any) => {
  const options = { timeZone, hour12: false, timeZoneName: "short" as TimeZoneNameType }
  const result = new Intl.DateTimeFormat("en-US", options).formatToParts()
  return result.find(part => part.type === "timeZoneName")!.value
}

export const timeZones = getTimeZones()

export const timeZone = dayjs.tz.guess()

export const simpleFormat = (
  date: dayjs.ConfigType,
  format = "YYYY-MM-DD",
  useUtcTimezone = true,
) => dateFormat(date, format, false, useUtcTimezone)

export const convertToUTC = (date: dayjs.ConfigType) => {
  if (!date) return ""
  return dayjs(date).utc().format()
}

export const lastDayOfMonth = (date: dayjs.ConfigType, format = "YYYY-MM-DD") => {
  if (!date) return ""
  return dayjs.utc(date).local().endOf("month").format(format)
}

export const firstDayOfMonth = (date: dayjs.ConfigType, format = "YYYY-MM-DD") => {
  if (!date) return ""
  return dayjs.utc(date).local().startOf("month").format(format)
}

/**
 *
 * @param date
 * @param format format
 * @param withTimeZoneName To show or not to show timezone
 * @param useUtcTimezone to utc time
 * @param timeZoneName timezone name
 * @returns
 */
export const dateFormat = (
  date: dayjs.ConfigType,
  format = "ddd, MMM D, YYYY h:mm a",
  withTimeZoneName = true,
  useUtcTimezone = true,
  timeZoneName?: string,
) => {
  if (!date || !dayjs(date).isValid()) return ""

  let result = ""
  let dateTime = dayjs(date)
  const timezone = getTimezone()

  if (useUtcTimezone) {
    dateTime = dayjs.utc(date).tz(timezone)
  }
  result = dateTime.format(format)

  if (withTimeZoneName) {
    timeZoneName = timeZoneName || getTimeZoneName(timezone)
    result = result + " " + timeZoneName
  }

  return result
}

export const date2ms = (date: dayjs.ConfigType) => {
  return dayjs(date).valueOf()
}

export const dateIsBefore = (date: dayjs.ConfigType, targetDate: dayjs.ConfigType) => {
  return dayjs(date).isBefore(dayjs(targetDate))
}

export const dateIsAfter = (date: dayjs.ConfigType, targetDate: dayjs.ConfigType) => {
  return dayjs(date).isAfter(dayjs(targetDate))
}

export const isSameDay = (date1: dayjs.ConfigType, date2: dayjs.ConfigType) => {
  return dayjs(date1).isSame(date2, "day")
}

export const formatMilliseconds = (ms: number) => {
  const seconds = Math.floor(ms / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const remainingSec = seconds % 60
  const remainingMin = minutes % 60
  const remainingHours = hours % 24

  if (days > 0) {
    return remainingHours !== 0 ? days + "d " + remainingHours + "h" : days + "d"
  } else if (hours > 0) {
    return remainingMin !== 0 ? remainingHours + "h " + remainingMin + "m" : remainingHours + "h"
  } else if (minutes > 0) {
    return remainingSec !== 0 ? remainingMin + "m " + remainingSec + "s" : remainingMin + "m "
  } else {
    return remainingSec !== 0 ? remainingSec + "s" : ms + "ms"
  }
}

export const formatMillisecondsDiff = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (days > 0) {
    return `${days}d ago`
  } else if (hours > 0) {
    return `${hours}hr ago`
  } else if (minutes > 0) {
    return `${minutes}m ago`
  } else {
    return `< 1m ago`
  }
}

/**
 * Return period daterange label
 */
export const getDaterangeLabel = (period: any) => {
  const [startDate, endDate] = period.dateRange
  const format = ["last_1w", "this_w"].includes(period.value) ? "MMM D, YYYY" : "MMM, YYYY"
  const start = dateFormat(startDate, format, false)
  const end = dateFormat(endDate, format, false)
  return `${start}${end ? " ~ " + end : ""}`
}

export function formatDateRange(date: Date): string {
  return date.toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })
}

/**
 * Format duration in seconds to string
 * @param seconds duration in seconds
 * @returns duration string in format "1d 2h 3min"
 */
export function formatDuration(seconds: number): string {
  return _formatDuration(seconds, "min")
}

export function formatDurationV2(seconds: number): string {
  return _formatDuration(seconds, "m")
}

/**
 * Format duration in seconds to string
 * @param seconds duration in seconds
 * @returns duration string in format "1d 2h 3min"
 */
function _formatDuration(seconds: number, min = "min"): string {
  if (seconds < 0) {
    return "0min"
  }
  if (seconds < 1 && seconds > 0) {
    return `${seconds}s`
  }

  if (seconds < 60) return `${seconds.toFixed(0)}s`

  const days = Math.floor(seconds / (3600 * 24))
  seconds %= 3600 * 24
  const hours = Math.floor(seconds / 3600)
  seconds %= 3600
  const minutes = Math.floor(seconds / 60)
  seconds %= 60

  const formattedParts: string[] = []
  if (days > 0) {
    formattedParts.push(`${days}d`)
  }
  if (hours > 0) {
    formattedParts.push(`${hours}h`)
  }
  if (minutes > 0) {
    formattedParts.push(`${minutes}${min}`)
  }
  // show seconds
  // if (seconds > 0) {
  //     formattedParts.push(`${seconds}s`);
  // }

  return formattedParts.join(" ")
}
