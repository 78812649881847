import { PreferencesDetail } from "@/api/personalization/types"
import { create } from "zustand"
import { persist } from "zustand/middleware"
import { PresetResourceFilters } from "@/pages/ProposalSetup/Proposal"

type State = {
  preferences: Array<PreferencesDetail>
}

type Action = {
  getPreference: (page: string, key: string) => PreferencesDetail | null
  setPreferences: (preferences: PreferencesDetail[]) => void
  updatePreferences: (preference: PreferencesDetail) => void
  reset: () => void
  setProposalFiltersPreference: (preferences: PresetResourceFilters) => void
}

const setPreference = (state: State & Action, preference: PreferencesDetail) => {
  const result = state.preferences.findIndex(item => item.id == preference.id)
  if (result !== -1) {
    state.preferences[result] = preference
  } else {
    state.preferences.push(preference)
  }

  return state.preferences
}

const initialState: State = {
  preferences: [],
}

export const usePreferencesStore = create<State & Action>()(
  persist(
    (set, get) => ({
      ...initialState,
      /**
       * Get a single preference
       */
      getPreference: (page, key) =>
        get().preferences.find(p => p.page === page && p.preference === key) || null,
      /**
       * set preferences
       */
      setPreferences: preferences => set({ preferences }),
      /**
       * Update preferences
       */
      updatePreferences: preference =>
        set(state => ({
          preferences: setPreference(state, preference),
        })),
      /**
       * Reset preferences
       */
      reset: () => set(initialState),
      /**
       * update preference for proposal page, temporary solution for adding filters when jumping from other pages
       */
      setProposalFiltersPreference: (filters) => {
        const prev = get().getPreference("proposalPage", "filters")
        const filtersList = Object.keys(filters).map((key) => {
          return { field: key, value: filters[key] }
        })
        const filtersString = JSON.stringify(filtersList)
        // only update if prev preference is not empty
        if (prev) {
          prev.value = filtersString
          setPreference(get(), prev)
        }
      }
    }),
    { name: "InSight_Preferences" }
  )
)
