import { Box, LinearProgress } from "@mui/material"
import { Suspense } from "react"

const lazyLoad = (Comp: React.LazyExoticComponent<any>): React.ReactNode => {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            zIndex: 1001,
            mt: "0px !important",
          }}
        >
          <LinearProgress />
        </Box>
      }
    >
      <Comp />
    </Suspense>
  )
}

export default lazyLoad
