import { create } from "zustand"
import { persist } from "zustand/middleware"

type Session = {
  acl?: string[] // Domain access control list
  email?: string // the authenticated user's email
  name?: string // The authenticated user's full name
  username?: string // The authenticated user's username
  display_name?: string // The authenticated user's display_name
  userId?: number // The authenticated user's user id
  employeeId?: number | null // The ODS employee id
  accessToken?: string // The authentication token which asserts this client has passed authentication
  roles?: { id: number; role_id: number; name: string }[] // user roles
  isClientFilter?: boolean // Indicate whether the user is a client filter
  clients?: any[] // ClientIDs of the clients that the user manages
  teams?: any[] // The teams LOB (client_id, products) current user is in
  managedTeams?: any[] // The teams LOB (client_id, products) current user is in
  clientAccess?: any
  isTeamMember?: boolean // If current user is team member
  serverVersion?: string // Web service version
  newServerVersion?: boolean // Indicate whether the UI need to be refreshed due to new server version being deployed
  newUIVersion?: boolean // Indicate whether the UI need to be refreshed due to new UI version being deployed
  releaseVersion?: string // Release version number
  isNew?: boolean // Whether it is a new user
  hasSubordinates?: boolean // Whether current logged-in user has subordinates in success factor
  isManagerAbove?: boolean // Whether current logged-in user's job title is manager and above
  isImpersonated?: boolean // Indicate whether current login user is normal login or impersonated login
  viewState?: any // Used to store if it is the first time view this page after login
  accountAccess?: { id: string; accessible: boolean } // Account's id has permission to access it according to client, save only the current one.
}

type State = {
  session: Session | null
}

type Action = {
  updateSession: (value: Session) => void
  reset: () => void
}

const initialState: State = { session: null }

export const useSessionStore = create<State & Action>()(
  persist(
    (set, get) => ({
      ...initialState,
      /**
       * Update session state
       */
      updateSession: value => set(state => ({ session: { ...state.session, ...value } })),
      /**
       * Reset session state
       */
      reset: () =>
        set({
          session: {
            serverVersion: get().session?.serverVersion || "",
            releaseVersion: get().session?.releaseVersion || "",
          },
        }),
    }),
    { name: "InSight" },
  ),
)
