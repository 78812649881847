import { ThemeMode } from "@/types/config"
import { ThemeOptions } from "@mui/material"

export const getDesignTokens = (mode: ThemeMode): ThemeOptions => {
  return {
    palette: {
      mode,
      primary: {
        main: "#27AAE1",
      },
      error: {
        main: "#D32F2F",
      },
      text: {
        primary: mode === ThemeMode.Dark ? "#ffffff99" : "#858585",
      },
    },
    typography: {
      fontFamily: "IBM Plex Sans, Roboto, Inter, system-ui",
      fontWeightRegular: 500,
      // h6: { fontSize: 16, color: "#6c757d" },
      caption: { fontSize: 14, color: "#6c757d" },
      body2: { fontSize: 14, fontWeight: 400 },
    },
    components: {
      MuiInputBase: {
        defaultProps: {
          size: "small",
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            lineHeight: "normal",
          },
          sizeMedium: {
            padding: "8px 12px",
          },
          contained: {
            color: "#fff",
          },
        },
        defaultProps: {
          disableRipple: true,
          disableElevation: true,
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: "4px",
            padding: 0,
            color: mode === ThemeMode.Dark ? "#a8a8a8" : "#484848",
          },
        },
        defaultProps: {
          disableRipple: true,
          size: "small",
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            paddingLeft: 0,
            paddingRight: 0,
            minWidth: 0,
          }
        }
      }
    },
  }
}
