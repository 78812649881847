import { ThemeMode } from "@/types/config"
import { type AlertColor } from "@mui/material"
import { create } from "zustand"
import { proposalSetupApis } from "@/api/proposalSetup"

interface Sanckbar {
  vertical: "top" | "bottom"
  horizontal: "left" | "center" | "right"
  open: boolean
  title?: string
  message: string
  severity?: AlertColor
}

interface ShowDataConfirm {
  open: boolean
  isDifferent?: boolean
  sourcePath?: string
  navigate?: string
  callback?: () => void
}

type State = {
  spinnerNum: number
  showSpinner: boolean
  snackbar: Sanckbar
  hasDialog: boolean
  stepOptions: any
  clientMappingOptions: any
  productOptions: any
  crmUserOptions: any
  crmAccountOptions: any
  crmAllAccountOptions: any
  crmOpportunityOptions: any
  clientOptions: any
  odsAssetOptions: any
  vvtOptions: any
  paperOptions: any
  leftover: any
  silverCrmUserOptions: any
  accountTypeOptions: any
  accountIndustryOptions: any
  showDataConfirm: ShowDataConfirm
  userOptions: any[]
  accountNameOptions: any[]
  themeMode: ThemeMode
}

type Action = {
  spinnerNumIncrement: () => void
  spinnerNumDecrement: () => void
  setShowSpinner: (show: boolean) => void
  updateSnackbar: (snackbar: Partial<Sanckbar>) => void
  setHasDialog: (hasDialog: boolean) => void
  setStepOptions: (stepOptions: any) => void
  setClientMappingOptions: (clientMappingOptions: any) => void
  setProductOptions: (stepOptions: any) => void
  setCrmUserOptions: (crmUserOptions: any) => void
  setCrmAccountOptions: (crmAccountOptions: any) => void
  setAllCrmAccountOptions: (crmAccountOptions: any) => void
  setCrmOpportunityOptions: (crmOpportunityOptions: any) => void
  setClientOptions: (clientOptions: any) => void
  setOdsAssetOptions: (odsAssetOptions: any) => void
  setVvtOptions: (vvtOptions: any) => void
  setPaperOptions: (paperOptions: any) => void
  setLeftover: (leftover: any) => void
  setSilverCrmUserOptions: (silverCrmUserOptions: any) => void
  setAccountTypeOptions: (accountTypeOptions: any) => void
  setAccountIndustryOptions: (accountIndustryOptions: any) => void
  setAccountNameOptions: (accountNameOptions: any) => void
  setShowDataConfirm: (show: ShowDataConfirm) => void
  setUserOptions: (userOptions: any[]) => void
  setThemeMode: (themeMode: ThemeMode) => void
  fetchAndSetProposalOptions: () => Promise<void>
}

export const useGlobalStore = create<State & Action>((set, get) => ({
  spinnerNum: 0,
  spinnerNumIncrement: () => {
    const q = get().spinnerNum + 1
    set(() => ({ showSpinner: q > 0, spinnerNum: q }))
  },
  spinnerNumDecrement: () => {
    const q = get().spinnerNum - 1
    set(() => ({ showSpinner: q > 0, spinnerNum: q }))
  },
  showSpinner: false,
  snackbar: {
    vertical: "top",
    horizontal: "center",
    open: false,
    message: "",
  },
  setShowSpinner: show => set(() => ({ showSpinner: show })),
  updateSnackbar: snackbar => set(state => ({ snackbar: { ...state.snackbar, ...snackbar, title: snackbar.title } })),
  hasDialog: false,
  setHasDialog: hasDialog => set(() => ({ hasDialog })),
  stepOptions: null,
  setStepOptions: stepOptions => set(() => ({ stepOptions })),
  productOptions: null,
  setProductOptions: productOptions => set(() => ({ productOptions })),
  clientMappingOptions: null,
  setClientMappingOptions: clientMappingOptions => set(() => ({ clientMappingOptions })),
  crmUserOptions: [],
  setCrmUserOptions: crmUserOptions => set(() => ({ crmUserOptions })),
  crmAccountOptions: [],
  setCrmAccountOptions: crmAccountOptions => set(() => ({ crmAccountOptions })),
  crmAllAccountOptions: [],
  setAllCrmAccountOptions: crmAllAccountOptions => set(() => ({ crmAllAccountOptions })),
  crmOpportunityOptions: [],
  setCrmOpportunityOptions: crmOpportunityOptions => set(() => ({ crmOpportunityOptions })),
  clientOptions: [],
  setClientOptions: clientOptions => set(() => ({ clientOptions })),
  odsAssetOptions: [],
  setOdsAssetOptions: odsAssetOptions => set(() => ({ odsAssetOptions })),
  vvtOptions: [],
  setVvtOptions: vvtOptions => set(() => ({ vvtOptions })),
  paperOptions: [],
  setPaperOptions: paperOptions => set(() => ({ paperOptions })),
  leftover: {},
  setLeftover: leftover => set(() => ({ leftover })),
  silverCrmUserOptions: [],
  setSilverCrmUserOptions: silverCrmUserOptions => set(() => ({ silverCrmUserOptions })),
  accountTypeOptions: [],
  setAccountTypeOptions: accountTypeOptions => set(() => ({ accountTypeOptions })),
  accountIndustryOptions: [],
  setAccountIndustryOptions: accountIndustryOptions => set(() => ({ accountIndustryOptions })),
  showDataConfirm: { open: false, isDifferent: false },
  setShowDataConfirm: show => set(() => ({ showDataConfirm: show })),
  userOptions: [],
  setUserOptions: userOptions => set(() => ({ userOptions: userOptions })),
  accountNameOptions: [],
  setAccountNameOptions: accountNameOptions => set(() => ({ accountNameOptions })),
  themeMode: ThemeMode.Light,
  setThemeMode: themeMode => set({ themeMode }),
  fetchAndSetProposalOptions: async () => {
    const resp = await proposalSetupApis.fetchOptions()
    const clientRes = await proposalSetupApis.fetchClientOptions(true)
    const accountNameSet = new Set()
    JSON.parse(resp.data.all_accounts || "[]").forEach((item: any) =>
      accountNameSet.add(item?.name),
    )
    const accountNameOptions = Array.from(accountNameSet)
      .filter(Boolean)
      .map(item => ({
        id: item,
        name: item,
      }))
    set(() => ({
      crmUserOptions: JSON.parse(resp.data.users || "[]"),
      crmAccountOptions: JSON.parse(resp.data.accounts || "[]"),
      crmAllAccountOptions: JSON.parse(resp.data.all_accounts || "[]"),
      crmOpportunityOptions: JSON.parse(resp.data.opportunities || "[]"),
      odsAssetOptions: JSON.parse(resp.data.ods_assets || "[]"),
      vvtOptions: resp.data.vvt_options || [],
      paperOptions: resp.data.paper_options || [],
      silverCrmUserOptions: JSON.parse(resp.data.crm_users || "[]"),
      accountTypeOptions: JSON.parse(resp.data.account_types || "[]"),
      accountIndustryOptions: JSON.parse(resp.data.account_industries || "[]"),
      accountNameOptions,
      clientOptions: clientRes.data,
    }))
  },
}))
