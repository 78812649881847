export const enum ThemeMode {
  Light = "light",
  Dark = "dark",
}

export const enum ROLE_ID {
  ADMIN = 1,
  ENHANCED = 2,
  BASIC = 3,
  BETA = 4,
  WORKLOG = 5,
  PROPOSAL_VIEW_PRICE = 7,
  PROPOSAL_CREATE = 8,
  PROPOSAL_CONFIGURE = 9,
  PROPOSAL_ACTIVATE = 10,
  PROPOSAL_REVENUE = 11,
  MESSAGE_CENTER = 12,
  TEAM_MANAGEMENT = 13,
  PROPOSAL_ACCOUNT = 14,
  AI_ADMIN = 15,
  USER_REPORT = 17,
}

export const enum AIBotInteractions {
  askQuestion = "Ask Question",
  regenerateAnswer = "Regenerate Answer",
  rateAnswer = "Rate Answer",
  copyAnswer = "Copy Answer",
  switchBot = "Switch Assistant",
  viewRelatedDocs = "View Doc List",
  checkRelatedDoc = "Check Related Doc",
  editChat = "Edit Chat",
  deleteChat = "Delete Chat",
  pinChat = "Pin Chat",
  newChat = "Create Chat",
  viewBotConfig = "View Assistant",
  updateBotConfig = "Update Assistant",
  createBot = "Create Assistant",
  deleteBot = "Delete Assistant",
}
