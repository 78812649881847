import http from "@/utils/http"

// buildID approval related API URL enumeration
enum apiUrlEnum {
  BASE = "/v1/buildid_approval",
  BUILDID_GROUPS = "/v1/buildid_approval/groups",
  BUILDID_APPROVALS = "/v1/buildid_approval/approvals",
  FILTER_OPTIONS = "/v1/buildid_approval/options",
}

/**
 * Get the buildID approval groups list from the server
 */
export const fetchBuildIDGroups = (params: any) => http.get(apiUrlEnum.BUILDID_GROUPS, { params })

/**
 * Get the buildID approvals list from the server
 */
export const fetchBuildIDApprovals = (params: any) =>
  http.get(apiUrlEnum.BUILDID_APPROVALS, { params, showSpinner: false })

/**
 * Approve the buildID approval
 */
export const approveBuildID = (data: any, isGroup = false) =>
  http.put(`${apiUrlEnum.BUILDID_APPROVALS}${isGroup ? "?is_group=true" : ""}`, data)

/**
 * Get the filter options from the server
 */
export const fetchFilterOptions = () => http.get(apiUrlEnum.FILTER_OPTIONS)

/**
 * Get the buildID proposal list from the server
 */
export const fetchBuildIDProposals = (buildIDRuleId: string) =>
  http.get(`${apiUrlEnum.BUILDID_APPROVALS}/${buildIDRuleId}/proposals`)

/**
 * Get the buildID job list from the server
 */
export const fetchBuildIDJobs = (buildIDRuleId: string, isOpen = true, period = "") =>
  http.get(`${apiUrlEnum.BASE}/${buildIDRuleId}/jobs?is_open=${isOpen}&period=${period}`)

/**
 * Get the buildID proposal list from the server
 */
export const fetchBuildIDRequestProposals = (buildIDRequestID: string) =>
  http.get(`${apiUrlEnum.BASE}/${buildIDRequestID}/crm-proposals`)

/**
 * Get comments of a buildID from the server
 */
export const fetchBuildIDComments = (buildID: number) =>
  http.get(`${apiUrlEnum.BASE}/${buildID}/notes`)

/**
 * Add comments of a buildID from the server
 */
export const addBuildIDComment = (buildID: number, data: any) =>
  http.post(`${apiUrlEnum.BASE}/${buildID}/notes`, data)

/**
 * Get the revisions from the server
 */
export const fetchRevisions = (buildID: number) =>
  http.get(`${apiUrlEnum.BASE}/${buildID}/revisions`)

/**
 * Get the revisions from the server
 */
export const fetchBuildIDRuleProposals = (buildIDRuleId: number) =>
  http.get(`${apiUrlEnum.BASE}/${buildIDRuleId}/proposals`)

/**
 * Fetch approval diffs detail
 */
export const fetchApprovalDiffs = (buildIDRequestID: number) =>
  http.get(`${apiUrlEnum.BASE}/${buildIDRequestID}`, { showSpinner: false })

/**
 * Fetch buildid approval categories from web service
 */
export const fetchApprovalCategories = () =>
  http.get(`${apiUrlEnum.BASE}/categories`, { showSpinner: false })
