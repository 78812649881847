import { useSessionStore } from "@/stores/session"

export const ADMIN_ROLE_ID = 1
export const ENHANCED_ROLE_ID = 2
export const BASIC_ROLE_ID = 3
export const BETA_ROLE_ID = 4
export const WORKLOG_ID = 5
export const PROPOSAL_VIEW_PRICE_ID = 7
export const PROPOSAL_CREATE_ID = 8
export const PROPOSAL_CONFIGURE_ID = 9
export const PROPOSAL_ACTIVATE_ID = 10
export const PROPOSAL_REVENUE_ID = 11
/**
 * @deprecated
 */
export const MESSAGE_CENTER_ID = 12 // Message Center role is being removed
export const PROPOSAL_ACCOUNT_ID = 14
export const TEAM_MANAGEMENT_ID = 13
export const CLIENT_PROFILE_ALL = 16
export const USER_REPORT_ID = 17
export const DATA_RECONCILIATION_ID = 18

const PROPOSAL_ROLES: Record<string, string> = {
  NON_PROPOSAL_USER: "non-proposal-user",
  PROPOSAL_VIEW_PRICE_USER: "proposal-view-price-user",
  PROPOSAL_CREATE_USER: "proposal-create-user",
  PROPOSAL_CONFIGURE_USER: "proposal-configure-user",
  PROPOSAL_ACTIVATE_USER: "proposal-activate-user",
  PROPOSAL_REVENUE_USER: "proposal-revenue-user",
  PROPOSAL_ACCOUNT_USER: "proposal-account-user",
}

const PROPOSAL_ROLE_PERMISSIONS: any = {
  [PROPOSAL_ROLES.NON_PROPOSAL_USER]: {
    canEditDetail: false,
    canEditFiles: false,
    canEditAssets: false,
    canEditClientProducts: false,
    canViewPricePoints: false,
    canChangeState: false,
    canViewRevenue: false,
    canEditTier: false,
    canViewAccount: false,
  },
  [PROPOSAL_ROLES.PROPOSAL_VIEW_PRICE_USER]: {
    canCreateProposal: false,
    canEditDetail: false,
    canEditFiles: false,
    canEditAssets: false,
    canEditClientProducts: false,
    canViewPricePoints: true,
    canChangeState: false,
    canViewRevenue: false,
    canEditTier: false,
    canViewAccount: false,
  },
  [PROPOSAL_ROLES.PROPOSAL_CREATE_USER]: {
    canCreateProposal: true,
    canEditDetail: true,
    canEditFiles: true,
    canEditAssets: true,
    canEditClientProducts: false,
    canViewPricePoints: true,
    canChangeState: true,
    canViewRevenue: false,
    canEditTier: true,
    canViewAccount: false,
  },
  [PROPOSAL_ROLES.PROPOSAL_CONFIGURE_USER]: {
    canCreateProposal: true,
    canEditDetail: true,
    canEditFiles: true,
    canEditAssets: true,
    canEditClientProducts: true,
    canViewPricePoints: true,
    canChangeState: true,
    canViewRevenue: false,
    canEditTier: true,
    canViewAccount: false,
  },
  [PROPOSAL_ROLES.PROPOSAL_ACTIVATE_USER]: {
    canCreateProposal: true,
    canEditDetail: true,
    canEditFiles: true,
    canEditAssets: true,
    canEditClientProducts: true,
    canViewPricePoints: true,
    canChangeState: true,
    canViewRevenue: false,
    canEditTier: true,
    canViewAccount: false,
  },
  [PROPOSAL_ROLES.PROPOSAL_REVENUE_USER]: {
    canCreateProposal: false,
    canEditDetail: false,
    canEditFiles: false,
    canEditAssets: false,
    canEditClientProducts: false,
    canViewPricePoints: false,
    canChangeState: false,
    canViewRevenue: true,
    canEditTier: false,
    canViewAccount: false,
  },
  [PROPOSAL_ROLES.PROPOSAL_ACCOUNT_USER]: {
    canCreateProposal: false,
    canEditDetail: false,
    canEditFiles: false,
    canEditAssets: false,
    canEditClientProducts: false,
    canViewPricePoints: false,
    canChangeState: false,
    canViewRevenue: false,
    canEditTier: false,
    canViewAccount: true,
  },
}

function getProposalPermissionsByRole(roles: any) {
  const permissions: Record<string, boolean> = {}
  roles.forEach((role: string) => {
    const rolePermissions = PROPOSAL_ROLE_PERMISSIONS[role] || {}

    for (const key in rolePermissions) {
      const value = rolePermissions[key]
      if (key in permissions) {
        permissions[key] = permissions[key] || value
      } else {
        permissions[key] = value
      }
    }
  })

  return permissions
}

export const useRoles = () => {
  const session = useSessionStore(state => state.session)
  const roles: any[] = session?.roles || []

  const isAdminRole = roles.some(role => role.role_id === ADMIN_ROLE_ID)
  const isEnhancedRole = roles.some(role => role.role_id === ENHANCED_ROLE_ID)
  const isBetaRole = roles.some(role => role.role_id === BETA_ROLE_ID)
  const isWorkLogRole = roles.some(role => role.role_id === WORKLOG_ID)
  const isDataReconciliationRole = roles.some(role => role.role_id === DATA_RECONCILIATION_ID)

  const isProposalView = roles.some(role => role.role_id === PROPOSAL_VIEW_PRICE_ID)
  const isProposalCreate = roles.some(role => role.role_id === PROPOSAL_CREATE_ID)
  const isProposalConfigure = roles.some(role => role.role_id === PROPOSAL_CONFIGURE_ID)
  const isProposalActivate = roles.some(role => role.role_id === PROPOSAL_ACTIVATE_ID)
  const isProposalRevenue = roles.some(role => role.role_id === PROPOSAL_REVENUE_ID)
  const isProposalAccount = roles.some(role => role.role_id === PROPOSAL_ACCOUNT_ID)
  const isTeamManagementRole = roles.some(role => role.role_id === TEAM_MANAGEMENT_ID)
  const isClientProfileAllRole = roles.some(role => role.role_id === CLIENT_PROFILE_ALL)
  const isUserReportRole = roles.some(role => role.role_id === USER_REPORT_ID)

  const proposalPermissions = getProposalPermissionsByRole([
    isProposalView ? "proposal-view-price-user" : "non-proposal-user",
    isProposalCreate ? "proposal-create-user" : "non-proposal-user",
    isProposalConfigure ? "proposal-configure-user" : "non-proposal-user",
    isProposalActivate ? "proposal-activate-user" : "non-proposal-user",
    isProposalRevenue ? "proposal-revenue-user" : "non-proposal-user",
    isProposalAccount ? "proposal-account-user" : "non-proposal-user",
  ])

  /**
   * The clients which is being managed by current user
   */
  const clients: any[] = session?.clients || []
  /**
   * The teams current user is in
   */
  const teams: any[] = session?.teams || []
  /**
   * Current user identity key
   */
  const userIdentity = session?.userId
  /**
   * If current user is a client owner
   */
  const isClientFilter = session?.isClientFilter
  /**
   * If current user is a member of a team
   */
  const isTeamMember = session?.isTeamMember

  /**
   * Return true if user has a team that is a global alert team
   */
  const isGlobalTeamMember = teams.find((item: any) => !item.client_id)

  /**
   *  Whether current user could access team management UI
   */
  const canAccessTeam = isAdminRole || isClientFilter || isTeamMember

  /**
   *  Whether current user could access alert setting UI
   */
  const canAccessAlert = canAccessTeam

  /**
   * If user can access Proposal Setup page
   */
  const canAccessProposalSetup = isProposalView || isProposalConfigure

  /**
   * Return true if current
   */
  const canCreateAlert = (clientId: any, productID: any) => {
    return (
      isAdminRole ||
      clients?.includes(clientId) ||
      teams?.find((item: any) => {
        return (
          item.client_id === clientId &&
          (item.product_ids?.split(",").includes(productID.toString()) || !item.product_ids)
        )
      })
    )
  }

  /**
   * Return whether if current user can edit the alert setting
   * @param clientId client that is being assigned in the alert setting
   * @param productID product that is being assigned in the alert setting
   * @param alertCreatorUserId user that created the alert setting
   */
  const canEditAlert = (clientId: any, productID: any, alertCreatorUserId: any) => {
    return (
      isAdminRole ||
      clients.includes(clientId) ||
      (teams.find(
        (item: any) =>
          item.client_id === clientId && (item.product_id === productID || !item.product_id),
      ) &&
        (isTeamManagementRole || isClientTeamLead(clientId))) ||
      alertCreatorUserId === userIdentity
    )
  }

  /**
   * Return team if current user is the client team lead
   * @param clientId client that is being assigned in the alert setting
   */
  const isClientTeamLead = (clientId: any) => {
    return teams.find((item: any) => item.client_id === clientId && item.is_team_lead)
  }

  /**
   * Return whether if current user can edit the client profile
   * @param clientId : clientId of client profile page
   */
  const canEditClientProfile = (clientId: number | undefined) => {
    return !!(isAdminRole || clients?.includes(clientId) || isClientTeamLead(clientId))
  }

  return {
    isAdminRole,
    isEnhancedRole,
    isBetaRole,
    isWorkLogRole,
    isDataReconciliationRole,
    isClientFilter,
    isGlobalTeamMember,
    canAccessTeam,
    canAccessAlert,
    canEditAlert,
    canCreateAlert,
    canAccessProposalSetup,
    proposalPermissions,
    isProposalView,
    isProposalConfigure,
    isProposalCreate,
    isProposalActivate,
    isProposalRevenue,
    isTeamManagementRole,
    isClientProfileAllRole,
    isClientTeamLead,
    canEditClientProfile,
    isUserReportRole,
  }
}
