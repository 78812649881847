import { personalizationApi } from "@/api/personalization"
import { usePreferencesStore } from "@/stores/preferences"
import { useSessionStore } from "@/stores/session"
import { PreferencesDetail } from "@/api/personalization/types"
import { debounce } from "."

/**
 * Get the preference of the page and field
 * @param page the page name
 * @param field the preference field
 */
export const getPreference = (page: string, field: string) => {
  const preferences = usePreferencesStore.getState().preferences || []
  return (preferences.find(p => p.page === page && p.preference === field) ||
    {}) as PreferencesDetail
}

/**
 * Set the preference of the page and field
 * @param page the page name
 * @param field the preference field
 * @param value the preference value
 */
export const updatePreferences = (params: { page: string; preference: string; value: any }) => {
  const targetPreference = getPreference(params.page, params.preference)

  if (targetPreference && targetPreference.value === params.value) return

  const session = useSessionStore.getState().session || {}
  personalizationApi
    .setPersonalization({
      id: targetPreference?.id || null,
      ad_user: session.username || "",
      owner_email: session.email || "",
      ...params,
    })
    .then(({ data }) => {
      usePreferencesStore.setState(state => {
        const index = state.preferences.findIndex(p => p.id === data.id)
        if (index !== -1) {
          state.preferences[index] = data
        } else {
          state.preferences.push(data)
        }
        return {
          preferences: [...state.preferences],
        }
      })
    })
}

export const updatePreferencesDebounce = debounce(updatePreferences, 1000)
